import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Button, Loader } from 'gw-ui';
import MailchimpSubscribe from "react-mailchimp-subscribe"

import './Newsletter.scss';

const McUrl = "//monopolio.us10.list-manage.com/subscribe/post?u=b2cf78b5c156ebf38a8368231&amp;id=65e43a3e5a";



// a basic form
const CustomForm = ({ status, message, onValidated, inputClassName, inputPlaceholder }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  let buttonStatus = status;

  const renderIcon = (buttonStatus) => {
    let icon = <span className="material-icons icon">arrow_forward</span>
    if (buttonStatus === 'sending') {
      icon = <Loader />
    } else if (buttonStatus === 'success') {
      icon = <span className="material-icons icon">check</span>
    } else if (buttonStatus === 'error') {
      icon = <span className="material-icons icon">close</span>
    } else {
      icon = <span className="material-icons icon">arrow_forward</span>
    }
    return icon
  }

  return (

    <div className="newsletter-wrap">
      <div className={inputClassName ? ("input-wrap " + inputClassName) : "input-wrap"}>
        <input
          ref={node => (email = node)}
          type="email"
          placeholder={inputPlaceholder ? inputPlaceholder : "Email"}
        />
      </div>
      <button className="button circle large transparent" onClick={submit}>
        <div className="button-content">
          {renderIcon(status)}
        </div>
      </button>
    </div>
  );
};

class Newsletter extends Component {

  getClassName = () => {
		var className = 'footer bg-dark mt-4 pt-2';
		if (this.props.className) {
		  className += ' ' + this.props.className
		}
		return className
  }

  render () {

		return (

		  <MailchimpSubscribe
        url={McUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
            inputClassName={this.props.inputClassName}
            inputPlaceholder={this.props.placeholder}
          />
        )}
      />

		);

  }
}


export default Newsletter;
