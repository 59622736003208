import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Button } from 'gw-ui';
import Eagle from 'components/Eagle';
import SanLuisLogo from 'components/SanLuisLogo';
import SanLuisLogoWide from 'components/SanLuisLogo/SanLuisLogoWide';
import Newsletter from 'components/Newsletter';
import socialMe from 'components/Newsletter';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import ContentfulRichText from 'components/ContentfulRichText'

import './Footer.scss';

class Footer extends Component {

  getClassName = () => {
		var className = 'footer bg-white';
		if (this.props.className) {
		  className += ' ' + this.props.className
		}
		return className
  }

  render () {
  	const { displayTitle, description, footerNewsletterText, footerNewsletterTitle, footerSections, showNewsletterSignup } = this.props

  // 	if (!document) {
		// 	return false
		// }
		// { index != 0 ? ( <div className="has-divider"><hr className="vertical-hr"/>) : null }

		return (

		  <footer className={this.getClassName()}>
		  	<div className="footer-content">
					<div className="container py-margin">
						<div className="grid-flex no-break middle">
							<div className="col grow">
								<SanLuisLogo/>
								<SanLuisLogoWide/>
							</div>
							<div className="col no-grow language-col">
								<div className="color-main-color">
									<a className="h5 py-1" href="/es-MX">Esp</a> | <a className="h5 py-1" href="/">En</a>
								</div>
							</div>
						</div>

						<div className="grid-flex break-lg wide-gutter v-spaced mt-4">
							<div className="col-5 order-3">
								<div className="has-divider">
									<hr className="vertical-hr"/>
									<h3 className="section-header pb-1 mb-1">{footerNewsletterTitle}</h3>
										<p className="text mb-1 pb-1 wide">{footerNewsletterText.footerNewsletterText}</p>
									<div className="newsletter-wrap">
										<Newsletter />
									</div>
								</div>
							</div>
							<div className="col grow">
								<div className="grid-flex break-md v-spaced">
									{footerSections.map((item, index) => {
										if ( index == 0 ) {
											return (
												<div className="col-6" key={item.id}>
													<h3 className="section-header pb-1 mb-1">{item.displayTitle}</h3>
														<div className="text mb-1 pb-1" >{documentToReactComponents(item.description.json)}</div>
													<ul className="link-list">
														{item.items.map((link, index) => (
															<li className="h6 light-text-color" key={link.id}><a href={link.to}>{link.label}</a></li>
														))}
													</ul>
												</div>
											)
										} else {
											return (
												<div className="col-6" key={item.id}>
													<div className="has-divider">
														<hr className="vertical-hr"/>
														<h3 className="section-header pb-1 mb-1">{item.displayTitle}</h3>
															<div className="text mb-1 pb-1" >{documentToReactComponents(item.description.json)}</div>
														<ul className="link-list">
															{item.items.map((link, index) => (
																<li className="h6 light-text-color" key={link.id}><a href={link.to}>{link.label}</a></li>
															))}
														</ul>
													</div>
												</div>
											)
										}
									})}
									
								</div>
							</div>
						</div>
					</div>
					<div className="mp-emblem"><Eagle/></div>
		  	</div>
				<div className="bottom-footer">
					<div className="grid-flex no-break gutter-none center">
						<div className="col">
							<p className="color-main-color">
								<a href="/legal">Privacy Policy • Terms & Conditions</a>
								{/* <Link to="/legal">Privacy Policy • Terms & Conditions</Link> */}
							</p>
						</div>
					</div>
				</div>
		  </footer>

		);

  }
}


export default Footer;
