import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom'
import { Button } from 'gw-ui';
import { Link, animateScroll as scroll } from "react-scroll";

import Logo from 'components/Logo';
import OffPremisePin from 'components/Stockists/components/OffPremisePin'



import './Header.scss';

class Header extends Component {

	state = {
		scrolled : false,
	}

  getClassName = () => {
		var className = 'main-header';
		if (this.props.className) {
		  className += ' ' + this.props.className
		}
		if (this.state.scrolled) {
		  className += ' scrolled'
		}
		return className
  }

  componentDidMount () {
  	this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
    let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    if( scrollTop > 10 ) {
    	if (!this.state.scrolled) {
    		this.setState({ scrolled: true });
    	}
  	} else {
  		if (this.state.scrolled) {
  			this.setState({ scrolled: false });
  		}
  	}
	}

	renderNavItems = (finder, finderIntro, navItems, lang) => {
		if ( finder ) {
			return (
				<a className="button circle large transparent" href={'/'+lang}>
	        <div className="button-content">
	          <span className="material-icons icon">arrow_back</span>
	        </div>
	      </a>
			)
		} else {
			return (
				<div className="hide-lg">
			  	<ul className="nav-list">
						{navItems.map((item, index) => {
							return (
								<li key={'nav-item-' + item.fields.blockId} className='nav-item'>
									<Link
									    activeClass="active"
									    to={item.fields.blockId}
									    spy={true}
									    hashSpy={true}
									    smooth={true}
									    offset={-130}
									    duration= {750}
									    className="small-caps py-1"
									>
										{lang === 'es-MX' ? item.fields.titleSpan : item.fields.title}
									</Link>
								</li>
							)
						})}
					</ul>
				</div>
			)
		}
	}

  render () {
  	let { navItems, currentSection, lang, finder, finderIntro } = this.props
  	navItems = [
  		{
  			fields: {
  				blockId: 'history',
  				title: 'History',
  				titleSpan: 'Historia'
  			}
  		},
  		{
  			fields: {
  				blockId: 'cerveza',
  				title: 'Cerveza',
  				titleSpan: 'Cerveza'
  			}
  		},
  		{
  			fields: {
  				blockId: 'tradition',
  				title: 'Tradition',
  				titleSpan: 'Tradición'
  			}
  		}
  	];

		return (
		  <div className={this.getClassName()}>
		  	<div className="inner-header">
			  	<div className="container">
			  		<div className="grid-flex middle no-break gutter-tight">
			  			<div className="col grow hide-lg">
								{this.renderNavItems(finder, finderIntro, navItems, lang)}			  	
			  			</div>
							<div className="col no-grow">
								<a href={"/"+lang} className="logo-link">
							  	<Logo />
						  	</a>
					  	</div>
					  	<div className="col grow align-right">
					  		<a className="small-caps py-1" href="/es-MX">Esp</a> | <a className="small-caps py-1" href="/">En</a>
					  		
					  	</div>
			  		</div>
			  	</div>
		  	</div>
		  </div>
		);
  }
}

export default Header;
