import React from 'react';

const SanLuisLogoWide = () => (
	<svg className="san-luis-logo wide" width="277.131px" height="28.975px" viewBox="0 0 277.131 28.975">
		<g>
			<path className="fill" fill="#EC1C24" d="M250.745,21.246l2.816,0.013l-0.166,0.239c-0.647,0.935-1.381,1.994-1.839,3.023
				c-0.141,0.337-0.274,0.675-0.407,1.013c-0.254,0.645-0.516,1.31-0.823,1.938l-0.213-0.054c-1.646-0.414-3.349-0.843-5.023-0.843
				h-13.092c0.521-0.782,1.134-1.833,1.134-2.659V5.024c0-0.984-0.562-2.271-1.154-3.179c0.944-0.128,2.035-0.188,3.411-0.188
				c0.687,0,1.375,0.014,2.054,0.027c0.547,0.011,1.087,0.021,1.615,0.025c-1.58,1.474-1.556,3.604-1.532,5.666l0.006,0.726v17.186
				h4.186l0.23-0.086C244.984,23.927,247.852,22.723,250.745,21.246z M188.114,13.202c-2.396-1.142-5.119-1.803-7.751-2.442
				l-1.1-0.269c-1.772-0.457-6.479-1.67-6.479-4.606c0-2.189,2.245-3.498,4.415-3.498c2,0,3.881,1.238,5.393,2.233
				c0.929,0.611,1.731,1.14,2.236,1.14h3.435c-0.527-0.806-0.993-1.65-1.443-2.47c-0.407-0.739-0.827-1.502-1.291-2.233
				c-0.431,0.142-0.673,0.322-0.908,0.496c-0.409,0.305-0.796,0.592-2.019,0.592c-0.899,0-1.789-0.222-2.73-0.456
				c-1.034-0.258-2.104-0.524-3.295-0.524c-3.797,0-7.832,2.411-7.832,6.881c0,5.269,5.119,6.457,10.069,7.606
				c4.269,0.991,8.301,1.928,8.301,5.524c0,3.293-3.396,5.331-6.543,5.331c-2.429,0-5.313-1.675-7.859-3.151
				c-0.932-0.541-1.812-1.052-2.553-1.433h-3.146c0.354,0.732,0.937,1.452,1.551,2.21c0.826,1.02,1.678,2.072,1.961,3.196
				c0.586-0.611,1.203-0.875,2.023-0.875c0.678,0,1.424,0.16,2.146,0.314c0.305,0.065,0.604,0.129,0.891,0.179
				c1.836,0.346,3.602,0.521,5.249,0.521c4.459,0,9.665-2.128,9.665-8.126C190.498,17.195,190.251,14.222,188.114,13.202z
				 M3.837,10.605c0-2.705,0.895-4.938,2.658-6.639c1.762-1.72,3.407-2.556,5.031-2.556c3.833,0,6.51,2.663,6.51,6.476
				c0,2.47-1.404,4.971-4.087,4.971c-1.9,0-2.64-0.587-3.165-2.513l-0.044-0.158l0.693,0.003c1.156,0,2.391-0.107,2.391-1.386
				c0-1.049-1.019-1.676-2.004-1.676c-1.471,0-2.265,1.336-2.265,2.593c0,3.48,3.064,4.721,5.933,4.721
				c2.253,0,3.863-0.502,4.922-1.535c0.847-0.846,2.845-4.304,2.845-5.313V3.494c-0.493,0.114-0.914,0.163-1.382,0.163
				c-1.565,0-2.787-0.691-4.081-1.423c-1.554-0.878-3.16-1.786-5.644-1.786c-5.829,0-12.023,4.639-12.023,13.234
				c0,8.02,5.066,13.201,12.907,13.201c5.767,0,9.051-1.772,11.695-6.259V19.67c-2.136,1.68-5.022,3.534-8.65,3.534
				C9.328,23.205,3.837,17.552,3.837,10.605z M206.037,18.854l0.19,0.17l-0.251,0.047c-0.341,0.063-0.708,0.095-1.122,0.095
				c-0.583,0-1.182-0.062-1.76-0.122c-0.566-0.06-1.152-0.12-1.71-0.12c-1.178,0.446-2.207,0.649-3.294,0.649
				c-2.345,0-4.719-1.398-4.719-4.07c0-0.48,0.28-1.108,0.485-1.567c1.209-2.59,4.31-3.989,6.148-4.62
				c-0.492-0.863-1.287-1.301-2.364-1.301c-0.635,0-1.973,0.836-2.772,1.336l-0.729,0.444l0.111-0.323
				c0.888-2.596,2.626-3.703,5.811-3.703c1.834,0,2.982,0.633,3.413,1.881c0.364,1.065,0.559,2.702,0.765,4.436
				C204.562,14.793,204.927,17.864,206.037,18.854z M201.224,17.203c-0.284-0.651-0.407-1.899-0.538-3.218
				c-0.119-1.209-0.242-2.458-0.495-3.293c-1.646,0.925-2.625,2.471-2.625,4.162c0,1.213,0.705,2.921,2.271,2.921
				c0.52,0,0.788-0.178,1.16-0.425C201.068,17.303,201.143,17.253,201.224,17.203z M219.079,17.3c0-1.507,0.012-3.021,0.024-4.536
				c0.012-1.523,0.024-3.05,0.024-4.575c0-1.608-1.919-2.597-3.295-2.597c-1.249,0-2.92,0.814-3.744,1.262V5.367l-0.852,0.012
				c-1.019,0.474-2.86,0.889-3.941,0.889h-0.271l0.42,0.487c0.602,0.688,0.999,1.143,0.999,2.134v8.312
				c0,0.854-0.302,1.334-1.078,1.709l0.055,0.237h5.667l0.066-0.23c-0.716-0.45-1.064-1.011-1.064-1.716V9.362
				c0-0.756,0.952-1.722,1.697-1.722c1.039,0,1.523,0.309,1.523,0.973c0,0.13-0.118,0.785-0.176,0.975c0,0.787,0.051,1.587,0.1,2.36
				c0.05,0.779,0.101,1.585,0.101,2.383c0,1.554-0.022,3.074-0.299,4.595l-0.026,0.147h5.195l-0.337-0.337
				C219.427,18.301,219.079,17.957,219.079,17.3z M254.673,17.374l0.024-10.218l-0.164,0.056c-1.363,0.454-2.942,0.918-4.329,0.918
				h-0.242l0.141,0.197c0.082,0.115,0.17,0.23,0.259,0.348c0.364,0.475,0.741,0.967,0.741,1.552v5.376
				c0,0.808-1.108,1.322-1.871,1.322c-0.901,0-1.673-1.043-1.673-1.896V5.621l-0.256-0.006c-0.697,0-1.409,0.184-2.097,0.36
				c-0.699,0.181-1.422,0.367-2.142,0.367h-0.195l0.082,0.177c0.103,0.224,0.261,0.45,0.429,0.689c0.286,0.41,0.583,0.835,0.583,1.23
				v8.388c0,1.56,2.089,2.595,3.471,2.595c1.157,0,2.675-0.473,3.669-0.968v0.619h4.594l0.071-0.228l-0.289-0.193
				C254.942,18.3,254.673,18.123,254.673,17.374z M259.733,4.295c1.471,0,2.098-0.561,2.098-1.874c0-1.309-0.901-2.296-2.098-2.296
				c-0.968,0-2.071,1.117-2.071,2.097C257.662,3.52,258.436,4.295,259.733,4.295z M261.631,17.225V5.752c0,0-0.663,0.191-0.916,0.268
				c-1.062,0.317-2.158,0.646-3.252,0.646l-0.199,0.005l0.09,0.181c0.677,1.355,0.957,1.917,0.957,3.408v6.766
				c0,0.814-0.497,1.337-1.244,1.87l0.072,0.227h5.967l-0.213-0.214C262.338,18.354,261.859,17.841,261.631,17.225z M275.466,11.672
				c-1.334-0.689-2.857-1.121-4.33-1.539l-0.412-0.117c-0.773-0.222-3.128-0.895-3.128-2.026c0-1.05,0.979-1.424,1.896-1.424
				c1.034,0,1.926,0.571,2.712,1.076c0.583,0.373,1.087,0.696,1.533,0.696h2.562l-0.231-0.217c-0.323-0.302-0.579-0.699-0.825-1.084
				c-0.336-0.521-0.683-1.061-1.215-1.401l-0.052-0.032l-0.057,0.02c-0.469,0.165-0.783,0.243-1.307,0.243
				c-0.459,0-0.918-0.031-1.377-0.062c-0.465-0.031-0.93-0.062-1.394-0.062c-2.197,0-4.719,0.406-4.719,3.57
				c0,2.813,2.856,3.743,5.376,4.564c2.062,0.672,4.011,1.307,4.011,2.85c0,0.964-1.195,1.871-2.122,1.871
				c-1.26,0-3.159-1.155-4.547-1.999c-0.275-0.167-0.53-0.322-0.819-0.471h-2.67l0.115,0.189c0.323,0.535,0.672,1.052,1.021,1.567
				c0.351,0.52,0.7,1.039,1.025,1.577l0.044,0.072l0.082-0.013c1.122-0.172,2.017-0.249,2.903-0.249c0.354,0,0.731,0.063,1.096,0.125
				c0.366,0.062,0.744,0.125,1.102,0.125c2.43,0,5.267-1.15,5.267-4.394C277.006,13.353,276.502,12.222,275.466,11.672z
				 M143.125,11.924c0-2.374,1.303-4.896,3.716-4.896c0.676,0,1.079,0.218,1.59,0.493l0.055,0.029V5.08l-0.062-0.085
				c-0.2-0.374-0.373-0.696-0.634-1.017l-0.143-0.175l0.224-0.028c0.85-0.106,1.812-0.366,2.784-0.751l0.171-0.067v11.496
				c0,0.567,0.26,0.948,0.708,1.414l0.204,0.212h-3.031l-0.169-0.304l-0.045,0.027c-0.667,0.41-0.916,0.563-1.955,0.563
				C144.018,16.366,143.125,13.973,143.125,11.924z M146.055,10.896c0,1.274,0.167,2.216,0.526,2.964
				c0.445,0.936,0.734,1.075,0.866,1.075c0.525-0.201,0.903-0.438,0.903-0.921V9.244c0-0.401-0.505-1.056-0.903-1.056
				C146.213,8.188,146.055,9.635,146.055,10.896z M160.484,14.487l0.14,0.196c-0.885,1.111-2.205,1.749-3.621,1.749
				c-2.554,0-4.711-2.157-4.711-4.71c0-2.221,1.747-4.694,4.255-4.694c2.172,0,2.96,1.841,3.656,3.466l0.01,1.066h-5.396
				c0.728,2.094,2.212,3.29,4.092,3.29C159.461,14.85,160.005,14.658,160.484,14.487z M154.706,10.568h2.61v-0.312
				c-0.015-0.124-0.427-1.178-0.524-1.357c-0.18-0.39-0.451-0.575-0.834-0.575c-1.11,0-1.19,0.615-1.19,1.629
				c0,0.127-0.045,0.234-0.084,0.329c-0.021,0.051-0.042,0.101-0.054,0.152C154.641,10.456,154.667,10.51,154.706,10.568z
				 M155.66,23.289c-1.086,0-2.173-0.512-3.676-1.219l-0.161-0.075c-2.055-0.983-3.097-1.414-4.441-1.414
				c-1.065,0-1.986,0.372-2.664,1.077c-0.826,0.859-1.229,2.157-1.166,3.752l0.25-0.001c0.061-1.797,2.552-2.523,3.58-2.523
				c0.949,0,1.914,0.48,3.136,1.088l0.544,0.27c2.284,1.058,3.352,1.38,4.566,1.38c1.065,0,1.968-0.354,2.61-1.026
				c0.801-0.837,1.169-2.117,1.094-3.804l-0.25,0.004C159.05,22.442,156.669,23.289,155.66,23.289z M39.437,16.832l0.14,0.196
				c-1.301,1.632-3.238,2.568-5.315,2.568c-3.748,0-6.915-3.167-6.915-6.915c0-3.259,2.563-6.89,6.241-6.89
				c3.176,0,4.335,2.706,5.357,5.094l0.01,1.496h-8.016c1.078,3.176,3.31,4.992,6.144,4.992
				C37.909,17.374,38.722,17.086,39.437,16.832z M30.829,11.035h3.957V10.51c-0.013-0.173-0.64-1.772-0.783-2.037
				c-0.274-0.597-0.695-0.884-1.289-0.884c-1.695,0-1.822,0.979-1.822,2.472c0,0.177-0.063,0.33-0.119,0.465
				c-0.032,0.078-0.065,0.154-0.083,0.235C30.708,10.826,30.757,10.933,30.829,11.035z M61.843,17.158
				c0.054-0.284,0.356-1.134,0.542-1.656c0.092-0.256,0.166-0.465,0.194-0.556c1.033-2.904,3.385-8.428,5.296-10.885l0.157-0.202
				h-5.514l0.138,0.197c0.533,0.759,1.076,2.372,1.076,3.197c0,1.419-1.665,6.446-2.678,7.786c-0.776-0.95-3.138-6.509-3.138-7.45
				l-0.002-0.266c-0.006-0.578-0.006-0.578,0.51-0.794l0.291-0.124l-0.05-0.239h-6.193l0.213,0.214
				c1.615,1.614,3.139,5.598,4.147,8.234l0.368,0.956c0.108,0.289,0.215,0.587,0.324,0.89c0.424,1.183,0.863,2.405,1.483,3.426
				l0.055,0.092l0.1-0.041c1.371-0.562,2.461-0.889,3.972-0.889h0.25l-0.15-0.2C62.822,18.297,62.368,17.712,61.843,17.158z
				 M79.562,16.832l0.14,0.196c-1.301,1.632-3.238,2.568-5.315,2.568c-3.749,0-6.916-3.167-6.916-6.915c0-3.259,2.563-6.89,6.241-6.89
				c3.176,0,4.335,2.706,5.357,5.094l0.01,1.496h-8.016c1.078,3.176,3.31,4.992,6.144,4.992
				C78.032,17.374,78.846,17.086,79.562,16.832z M70.953,11.035h3.957V10.51c-0.012-0.156-0.619-1.738-0.783-2.036
				c-0.274-0.598-0.695-0.885-1.289-0.885c-1.695,0-1.822,0.979-1.822,2.472c0,0.178-0.063,0.331-0.12,0.467
				c-0.031,0.077-0.064,0.153-0.082,0.233C70.832,10.826,70.881,10.933,70.953,11.035z M90.601,17.138
				c-0.279,0.147-0.448,0.236-0.993,0.236c-3.29,0-6.217-3.728-6.217-6.665c0-1.801,0.705-3.005,2.047-3.569h1.114
				c-0.265,0.501-0.564,1.116-0.564,1.398c0,1.116,1.193,2.296,2.321,2.296c1.181,0,2.496-0.861,2.496-2.097
				c0-2.179-2.553-2.97-4.269-2.97c-3.912,0-6.439,2.636-6.439,6.715c0,2.885,1.381,5.21,3.791,6.379
				c0.805,0.375,1.598,0.711,2.448,0.711c1.961,0,3.837-0.948,4.896-2.476l-0.149-0.188C90.876,16.995,90.729,17.072,90.601,17.138z
				 M103.715,16.832l0.14,0.196c-1.3,1.632-3.237,2.568-5.315,2.568c-3.748,0-6.914-3.167-6.914-6.915c0-3.259,2.562-6.89,6.239-6.89
				c3.176,0,4.335,2.706,5.357,5.094l0.01,1.496h-8.015c1.078,3.176,3.31,4.992,6.144,4.992
				C102.184,17.374,102.998,17.086,103.715,16.832z M95.106,11.035h3.957V10.51c-0.013-0.173-0.64-1.772-0.783-2.037
				c-0.275-0.597-0.695-0.884-1.288-0.884c-1.696,0-1.823,0.979-1.823,2.472c0,0.177-0.063,0.33-0.119,0.465
				c-0.032,0.078-0.065,0.154-0.083,0.235C94.984,10.824,95.033,10.931,95.106,11.035z M112.314,5.592
				c-1.558,0-2.118,0.621-2.894,1.479c-0.168,0.187-0.348,0.386-0.551,0.593V5.299l-0.188,0.111c-1.069,0.631-2.664,1.306-3.93,1.306
				h-0.198c0,0,0.208,0.428,0.279,0.567c0.297,0.584,0.666,1.312,0.666,1.829v7.812c0,0.786-0.351,1.424-1.043,1.895l0.07,0.229h5.592
				l0.038-0.244c-0.841-0.272-1.286-0.854-1.286-1.68v-5.79c0-0.744,1.224-2.097,1.897-2.097c0.232,0,0.414,0.065,0.643,0.147
				c0.312,0.111,0.7,0.251,1.354,0.251c1.289,0,2.122-0.842,2.122-2.146C114.886,6.179,113.595,5.592,112.314,5.592z M49.086,5.592
				c-1.558,0-2.118,0.621-2.894,1.479c-0.168,0.187-0.348,0.386-0.551,0.593V5.299l-0.188,0.111c-1.069,0.631-2.664,1.306-3.93,1.306
				h-0.198c0,0,0.208,0.428,0.279,0.567c0.297,0.584,0.666,1.312,0.666,1.829v7.812c0,0.786-0.351,1.424-1.043,1.895l0.07,0.229h5.593
				l0.038-0.244c-0.842-0.272-1.287-0.854-1.287-1.68v-5.79c0-0.744,1.224-2.097,1.897-2.097c0.232,0,0.414,0.065,0.643,0.147
				c0.312,0.111,0.7,0.251,1.354,0.251c1.289,0,2.122-0.842,2.122-2.146C51.657,6.179,50.366,5.592,49.086,5.592z M120.397,5.917
				c-1.323,0.375-2.671,0.874-4.044,0.874c0.673,1.348,0.973,1.946,0.973,3.469v6.765c0,0.923-0.599,1.473-1.298,1.972h5.666
				c-0.524-0.524-1.048-1.074-1.298-1.773V5.917z M135.579,18.854l0.19,0.17l-0.251,0.047c-0.341,0.063-0.708,0.095-1.122,0.095
				c-0.583,0-1.182-0.062-1.76-0.122c-0.566-0.06-1.152-0.12-1.71-0.12c-1.178,0.446-2.207,0.649-3.294,0.649
				c-2.345,0-4.718-1.398-4.718-4.07c0-0.482,0.281-1.112,0.487-1.572c1.206-2.585,4.307-3.985,6.146-4.615
				c-0.491-0.863-1.286-1.301-2.364-1.301c-0.633,0-1.969,0.834-2.768,1.333l-0.733,0.447l0.111-0.323
				c0.889-2.596,2.627-3.703,5.812-3.703c1.834,0,2.982,0.633,3.412,1.881c0.365,1.065,0.56,2.704,0.766,4.438
				C134.104,14.795,134.469,17.865,135.579,18.854z M130.766,17.203c-0.283-0.65-0.406-1.897-0.536-3.213
				c-0.12-1.212-0.244-2.462-0.497-3.298c-1.646,0.925-2.623,2.471-2.623,4.162c0,1.213,0.704,2.921,2.271,2.921
				c0.521,0,0.789-0.179,1.16-0.425C130.611,17.302,130.686,17.253,130.766,17.203z M121.424,0.235h-3.701l0.057,0.166
				c0.005,0.014,0.479,1.463-0.552,3.715l-0.059,0.127l1.254,0.415L121.424,0.235z"/>
			<path className="outline" d="M191.953,19.515c-0.019-0.33-0.046-0.664-0.091-1.002c-0.001-0.007-0.001-0.014-0.002-0.021c0-0.001,0-0.003-0.001-0.004
				c-0.037-0.269-0.084-0.538-0.142-0.803c-0.018-0.082-0.043-0.16-0.064-0.24c-0.046-0.182-0.093-0.362-0.151-0.538
				c-0.009-0.026-0.013-0.054-0.022-0.08c-0.016-0.045-0.042-0.084-0.058-0.129c-0.086-0.23-0.186-0.45-0.299-0.661
				c-0.041-0.076-0.082-0.152-0.127-0.226c-0.158-0.258-0.333-0.503-0.544-0.715l-1.361-1.361c0.059,0.059,0.096,0.135,0.151,0.198
				c-0.023-0.027-0.052-0.048-0.076-0.075c-0.115-0.125-0.229-0.248-0.362-0.356c-0.188-0.16-0.393-0.303-0.629-0.416
				c-2.75-1.319-5.964-2.007-8.869-2.723c-1.2-0.306-3.2-0.86-4.643-1.823c-0.071-0.12-0.135-0.242-0.186-0.369
				c-0.033-0.082-0.063-0.164-0.088-0.25c-0.021-0.073-0.039-0.146-0.054-0.222c-0.029-0.151-0.053-0.304-0.053-0.466
				c0-0.25,0.038-0.484,0.095-0.708c0.01-0.038,0.018-0.077,0.03-0.114c0.064-0.214,0.15-0.417,0.259-0.605
				c0.022-0.037,0.05-0.07,0.073-0.106c0.096-0.15,0.205-0.292,0.326-0.424c0.047-0.052,0.098-0.101,0.148-0.15
				c0.117-0.113,0.243-0.219,0.377-0.317c0.051-0.038,0.1-0.078,0.153-0.113c0.187-0.125,0.384-0.239,0.592-0.336
				c0.026-0.012,0.054-0.021,0.08-0.032c0.186-0.083,0.377-0.154,0.572-0.215c0.069-0.021,0.138-0.039,0.208-0.058
				c0.163-0.044,0.327-0.079,0.492-0.107c0.068-0.012,0.135-0.025,0.203-0.034c0.227-0.03,0.453-0.049,0.674-0.049
				c3.242,0,6.258,3.368,7.633,3.368h3.663c-1.08-1.6-1.865-3.34-2.917-4.968l-1.361-1.361c-0.908,0.272-1.073,0.697-1.725,0.934
				c-0.288,0.103-0.661,0.174-1.251,0.174c-1.867,0-3.733-0.982-6.02-0.982c-1.537,0-3.041,0.369-4.32,1.062
				c-2.135,1.152-3.648,3.205-3.648,5.949c0,0.192,0.019,0.369,0.032,0.55c0.003,0.048,0.004,0.097,0.008,0.144
				c0.017,0.178,0.042,0.35,0.071,0.518c0.01,0.061,0.022,0.121,0.034,0.181c0.156,0.768,0.44,1.433,0.831,2.013
				c0.008,0.012,0.016,0.024,0.025,0.036c0.238,0.347,0.51,0.666,0.819,0.956l1.218,1.218c1.624,1.624,4.231,2.435,6.907,3.091
				c0.765,0.187,1.535,0.362,2.29,0.539c0.377,0.089,0.751,0.178,1.117,0.27c1.755,0.44,3.349,0.944,4.51,1.712
				c0.011,0.018,0.025,0.033,0.035,0.051c0.021,0.037,0.038,0.077,0.058,0.115c0.067,0.126,0.127,0.256,0.178,0.392
				c0.018,0.047,0.034,0.096,0.05,0.144c0.049,0.15,0.089,0.305,0.118,0.467c0.006,0.031,0.013,0.06,0.018,0.091
				c0.032,0.205,0.05,0.418,0.05,0.643c0,0.651-0.142,1.247-0.385,1.787c-0.984,2.172-3.681,3.416-6.04,3.416
				c-3.27,0-7.423-3.115-10.398-4.589h-3.34c0.001,0.002,0.003,0.005,0.004,0.007h-0.012c0.819,1.901,3.274,3.734,3.634,5.797
				c0.002-0.003,0.005-0.005,0.007-0.007l1.36,1.36c0.59-0.688,1.207-1.01,2.091-1.01c0.954,0,2.063,0.323,3.003,0.491
				c1.74,0.323,3.508,0.519,5.276,0.519c0.411,0,0.818-0.024,1.222-0.058c0.107-0.009,0.213-0.02,0.32-0.032
				c0.35-0.038,0.695-0.088,1.035-0.153c0.056-0.011,0.113-0.017,0.168-0.029c0.384-0.079,0.758-0.178,1.125-0.291
				c0.094-0.029,0.185-0.061,0.277-0.093c0.3-0.101,0.592-0.214,0.876-0.34c0.073-0.032,0.148-0.061,0.22-0.095
				c0.337-0.158,0.659-0.335,0.969-0.528c0.07-0.044,0.136-0.092,0.205-0.138c0.249-0.166,0.487-0.344,0.714-0.534
				c0.069-0.058,0.14-0.114,0.207-0.174c0.268-0.24,0.52-0.496,0.75-0.772c0.04-0.048,0.074-0.1,0.112-0.149
				c0.191-0.241,0.365-0.497,0.525-0.764c0.029-0.048,0.066-0.088,0.094-0.137c0.02-0.035,0.031-0.077,0.051-0.112
				c0.182-0.336,0.346-0.686,0.477-1.062c0.004-0.011,0.01-0.02,0.014-0.03c0.016-0.047,0.023-0.101,0.038-0.148
				c0.091-0.283,0.167-0.577,0.228-0.882c0.01-0.051,0.029-0.096,0.038-0.148c0.011-0.063,0.01-0.134,0.02-0.198
				c0.046-0.298,0.076-0.606,0.091-0.923c0.004-0.075,0.02-0.142,0.021-0.218c0.002-0.08,0.003-0.161,0.003-0.242
				c0-0.294-0.008-0.607-0.022-0.926C191.963,19.684,191.958,19.601,191.953,19.515z M190.061,15.339
				c0.026,0.07,0.057,0.136,0.08,0.208c0.011,0.034,0.018,0.07,0.029,0.104c-0.041-0.128-0.09-0.251-0.138-0.374
				C190.041,15.298,190.053,15.317,190.061,15.339z M189.635,14.452c0.045,0.073,0.086,0.15,0.127,0.226
				c0.009,0.016,0.014,0.034,0.022,0.05c-0.058-0.111-0.119-0.219-0.185-0.323C189.61,14.421,189.625,14.435,189.635,14.452z
				 M180.833,27.343c-1.64,0-3.398-0.175-5.23-0.52c-0.284-0.049-0.583-0.113-0.888-0.178c-0.727-0.155-1.478-0.316-2.167-0.316
				c-0.776,0-1.396,0.238-1.961,0.763c-0.334-1.072-1.142-2.07-1.926-3.039c-0.558-0.69-1.09-1.347-1.443-2.006h2.883
				c0.779,0.388,1.638,0.887,2.547,1.415c2.56,1.486,5.461,3.17,7.924,3.17c3.207,0,6.668-2.086,6.668-5.456
				c0-3.696-4.079-4.643-8.398-5.646c-4.903-1.139-9.972-2.316-9.972-7.485c0-4.388,3.971-6.756,7.708-6.756
				c1.174,0,2.236,0.265,3.264,0.521c0.949,0.237,1.846,0.46,2.761,0.46c1.264,0,1.667-0.299,2.094-0.617
				c0.219-0.162,0.427-0.317,0.78-0.447c0.441,0.703,0.843,1.434,1.233,2.142c0.417,0.757,0.845,1.535,1.324,2.286h-3.205
				c-0.468,0-1.255-0.519-2.168-1.119c-1.525-1.004-3.423-2.254-5.461-2.254c-2.232,0-4.54,1.355-4.54,3.623
				c0,3.032,4.776,4.264,6.576,4.728l1.097,0.268c2.626,0.638,5.342,1.297,7.728,2.433c2.073,0.99,2.313,3.915,2.313,6.028
				C190.373,23.033,187.875,27.343,180.833,27.343z M173.364,7.303c-0.045-0.069-0.081-0.143-0.12-0.215
				c0.022,0.041,0.048,0.081,0.073,0.121C173.334,7.239,173.344,7.273,173.364,7.303z M173.149,6.906
				c-0.022-0.048-0.041-0.098-0.06-0.147c0.007,0.019,0.02,0.035,0.027,0.054C173.128,6.843,173.135,6.876,173.149,6.906z
				 M172.983,6.411c-0.008-0.038-0.013-0.077-0.019-0.116c0.003,0.016,0.008,0.031,0.011,0.047
				C172.979,6.364,172.978,6.389,172.983,6.411z M190.279,16.035c0.005,0.018,0.01,0.035,0.015,0.053
				c0.02,0.079,0.045,0.156,0.062,0.236c0.013,0.062,0.021,0.125,0.033,0.187C190.358,16.351,190.32,16.193,190.279,16.035z
				 M190.46,16.908c0.011,0.074,0.029,0.146,0.039,0.22c0.001,0.006,0.001,0.013,0.002,0.019c0.009,0.064,0.01,0.126,0.017,0.189
				C190.502,17.194,190.482,17.051,190.46,16.908z M190.585,18.068c0.002,0.029,0.006,0.059,0.008,0.087
				c0.003,0.051,0.006,0.102,0.008,0.153C190.597,18.229,190.59,18.148,190.585,18.068z M16.076,23.067
				c-0.73,0-1.443-0.067-2.133-0.194c-0.002,0-0.004-0.001-0.006-0.001c-0.327-0.06-0.648-0.136-0.965-0.223
				c-0.03-0.008-0.06-0.016-0.089-0.024c-0.314-0.089-0.622-0.19-0.925-0.303c-0.018-0.007-0.036-0.014-0.054-0.021
				c-1.274-0.485-2.442-1.183-3.468-2.049c-0.095-0.11-0.198-0.212-0.29-0.325c-0.09-0.112-0.171-0.231-0.257-0.346
				c-0.18-0.238-0.355-0.48-0.518-0.731c-0.092-0.142-0.176-0.289-0.263-0.435c-0.141-0.238-0.276-0.479-0.402-0.727
				c-0.08-0.158-0.156-0.317-0.229-0.479c-0.114-0.251-0.219-0.507-0.317-0.766c-0.065-0.171-0.13-0.341-0.188-0.516
				c-0.067-0.202-0.124-0.408-0.181-0.614c-0.054-0.198-0.108-0.396-0.153-0.597c-0.047-0.209-0.083-0.422-0.119-0.634
				c-0.039-0.23-0.079-0.459-0.105-0.692c-0.011-0.094-0.02-0.189-0.029-0.284c-0.034-0.377-0.058-0.757-0.057-1.142
				c0-0.232,0.014-0.461,0.028-0.689c0.006-0.104,0.01-0.209,0.019-0.312c0.02-0.222,0.05-0.442,0.083-0.659
				c0.01-0.063,0.015-0.127,0.026-0.19c0.005-0.029,0.013-0.056,0.019-0.085c0.073-0.399,0.177-0.787,0.3-1.168
				C5.839,8.757,5.87,8.651,5.908,8.547c0.037-0.102,0.078-0.203,0.119-0.303c0.065-0.159,0.135-0.316,0.21-0.471
				c0.047-0.098,0.096-0.196,0.147-0.292C6.445,7.369,6.512,7.26,6.577,7.15C6.71,6.928,6.854,6.711,7.009,6.499
				c0.064-0.088,0.126-0.176,0.194-0.262C7.429,5.953,7.67,5.675,7.941,5.412c1.32-1.277,3.018-2.526,4.955-2.526
				c1.49,0,2.801,0.421,3.847,1.157c0.024,0.033,0.049,0.065,0.072,0.098c0.144,0.211,0.276,0.432,0.394,0.663
				c0.04,0.079,0.075,0.163,0.112,0.244c0.081,0.179,0.153,0.364,0.219,0.554c0.033,0.096,0.07,0.189,0.098,0.287
				c0.069,0.238,0.122,0.485,0.165,0.738c0.023,0.133,0.04,0.269,0.056,0.406c0.021,0.181,0.032,0.366,0.039,0.554
				c0.004,0.1,0.013,0.197,0.013,0.299c0,0.896-0.184,1.802-0.554,2.584c-0.621,1.298-1.757,2.254-3.411,2.254
				c-0.563,0-1.005-0.061-1.376-0.181c-0.045-0.107-0.096-0.197-0.138-0.317c-0.057-0.164-0.111-0.342-0.163-0.532
				c-0.002-0.008-0.005-0.015-0.007-0.023c0.163,0,0.336,0.002,0.515,0.002c0.129,0,0.26-0.002,0.392-0.006
				c0.041-0.001,0.081-0.004,0.122-0.006c0.097-0.004,0.192-0.011,0.288-0.02c0.046-0.004,0.091-0.009,0.137-0.014
				c0.098-0.012,0.193-0.027,0.286-0.046c0.036-0.007,0.072-0.012,0.107-0.02c0.124-0.029,0.243-0.064,0.355-0.109
				c0.021-0.008,0.039-0.021,0.059-0.03c0.088-0.039,0.171-0.085,0.247-0.138c0.031-0.021,0.059-0.046,0.088-0.07
				c0.062-0.051,0.117-0.108,0.168-0.171c0.024-0.03,0.048-0.059,0.069-0.091c0.012-0.019,0.028-0.033,0.039-0.052
				c0.036-0.063,0.067-0.132,0.092-0.205c0.005-0.015,0.008-0.033,0.012-0.048c0.005-0.016,0.008-0.033,0.013-0.05
				c0.01-0.039,0.023-0.077,0.03-0.119c0.015-0.085,0.024-0.175,0.026-0.272c0-0.017,0.001-0.034,0.001-0.051
				c0-0.077-0.005-0.151-0.015-0.224c-0.003-0.02-0.01-0.037-0.014-0.057c-0.009-0.051-0.022-0.1-0.036-0.149
				c-0.009-0.033-0.013-0.069-0.024-0.101c-0.006-0.017-0.017-0.031-0.023-0.047c-0.029-0.075-0.065-0.145-0.105-0.213
				C15.073,9.333,15.058,9.3,15.038,9.27c-0.064-0.095-0.135-0.186-0.217-0.267L13.46,7.641c0.015,0.015,0.026,0.035,0.041,0.05
				c-0.015-0.016-0.034-0.027-0.05-0.042c-0.078-0.078-0.161-0.15-0.253-0.215c-0.042-0.03-0.086-0.057-0.131-0.084
				c-0.072-0.043-0.145-0.083-0.222-0.118c-0.063-0.03-0.126-0.057-0.192-0.081c-0.06-0.021-0.12-0.038-0.182-0.055
				c-0.082-0.023-0.162-0.046-0.246-0.06c-0.039-0.007-0.079-0.007-0.119-0.012c-0.093-0.011-0.186-0.028-0.279-0.028
				c-1.474,0-2.4,1.305-2.401,2.723c0,0.024,0.006,0.044,0.006,0.068c0.003,0.289,0.032,0.56,0.076,0.82
				c0.011,0.065,0.02,0.13,0.033,0.193c0.057,0.271,0.135,0.526,0.232,0.766c0.013,0.031,0.027,0.061,0.041,0.091
				c0.222,0.508,0.532,0.942,0.912,1.308c-0.023-0.019-0.047-0.038-0.068-0.059l0,0c0,0,0,0,0,0l1.361,1.361
				c1.155,1.155,2.976,1.654,4.828,1.654c1.768,0,3.705-0.295,5.01-1.572c0.096-0.096,0.209-0.23,0.331-0.386
				c0.009-0.011,0.016-0.02,0.025-0.031c0.118-0.153,0.245-0.332,0.377-0.526c0.015-0.022,0.029-0.041,0.044-0.063
				c0.129-0.192,0.263-0.4,0.397-0.618c0.019-0.031,0.038-0.061,0.057-0.092c0.132-0.216,0.264-0.44,0.393-0.669
				c0.011-0.02,0.023-0.038,0.034-0.058c0.012-0.022,0.023-0.043,0.035-0.065c0.094-0.169,0.187-0.339,0.276-0.508
				c0.004-0.008,0.008-0.015,0.012-0.023c0.092-0.175,0.178-0.348,0.26-0.518c0.022-0.045,0.042-0.088,0.063-0.133
				c0.065-0.138,0.126-0.273,0.183-0.404c0.014-0.033,0.03-0.067,0.043-0.099c0.065-0.155,0.122-0.302,0.171-0.44
				c0.004-0.011,0.01-0.023,0.013-0.034c0.005-0.015,0.006-0.026,0.011-0.041c0.057-0.169,0.103-0.327,0.127-0.46
				c0-0.002,0.001-0.005,0.002-0.007c0-0.001,0-0.001,0-0.002c0.001-0.008,0.001-0.014,0.003-0.022
				c0.011-0.064,0.019-0.125,0.021-0.178c0-0.009,0-0.018,0-0.027l0.001-4.252l-1.361-1.361v0h0c-0.478,0.116-0.912,0.178-1.383,0.188
				c-0.041,0.001-0.079,0.008-0.121,0.008c-3.339-0.002-5.078-3.214-9.722-3.214c-2.303,0-4.353,0.621-6.082,1.689
				C2.262,4.353,0,8.863,0,13.682c0,4.141,1.379,7.583,3.831,9.935c-0.059-0.052-0.119-0.102-0.175-0.157l0.001,0.001
				c0,0-0.001-0.001-0.001-0.001l1.361,1.361c2.245,2.246,5.446,3.535,9.373,3.535c2.394,0,4.418-0.301,6.173-1.041
				c0.501-0.211,0.981-0.459,1.44-0.745c0.46-0.287,0.9-0.612,1.322-0.98c1.057-0.92,2.005-2.105,2.883-3.604v0l0-1.221l-1.361-1.361
				C22.265,21.467,19.486,23.067,16.076,23.067z M6.529,18.222c0.064,0.084,0.118,0.176,0.184,0.259
				c-0.114-0.144-0.215-0.298-0.323-0.446C6.436,18.098,6.482,18.16,6.529,18.222z M6.01,17.49c0.02,0.03,0.036,0.063,0.056,0.093
				c-0.037-0.056-0.074-0.113-0.111-0.17C5.973,17.44,5.993,17.463,6.01,17.49z M5.748,17.056c0.005,0.008,0.008,0.016,0.013,0.023
				c-0.011-0.019-0.022-0.039-0.034-0.058C5.734,17.033,5.742,17.045,5.748,17.056z M5.346,16.328
				c0.036,0.072,0.066,0.147,0.104,0.217c-0.049-0.093-0.093-0.189-0.141-0.284C5.321,16.284,5.334,16.305,5.346,16.328z
				 M13.918,8.514c0.013,0.075,0.02,0.152,0.024,0.231c-0.003-0.093-0.015-0.183-0.033-0.27C13.912,8.488,13.916,8.501,13.918,8.514z
				 M13.835,8.218c0.013,0.032,0.021,0.066,0.032,0.1c-0.018-0.056-0.04-0.11-0.063-0.163C13.814,8.176,13.826,8.195,13.835,8.218z
				 M13.677,7.909c0.015,0.023,0.026,0.048,0.04,0.072c-0.031-0.051-0.065-0.099-0.101-0.147C13.635,7.859,13.659,7.882,13.677,7.909z
				 M24.603,20.556c-2.63,4.462-5.88,6.202-11.571,6.202c-7.765,0-12.782-5.133-12.782-13.076c0-3.456,1.155-6.704,3.251-9.145
				c2.196-2.557,5.267-3.965,8.647-3.965c2.451,0,4.043,0.9,5.582,1.77c1.309,0.74,2.545,1.439,4.143,1.439
				c0.422,0,0.82-0.041,1.256-0.133v3.943c0,0.963-1.994,4.412-2.806,5.223c-1.035,1.01-2.617,1.501-4.835,1.501
				c-2.808,0-5.808-1.207-5.808-4.596c0-1.196,0.75-2.467,2.14-2.467c0.903,0,1.878,0.593,1.878,1.55c0,1.049-0.867,1.26-2.265,1.26
				l-0.276-0.001l-0.254-0.001h-0.327l0.086,0.316c0.545,1.997,1.313,2.606,3.286,2.606c2.765,0,4.212-2.564,4.212-5.096
				c0-3.886-2.728-6.601-6.635-6.601c-1.657,0-3.331,0.848-5.118,2.591c-1.789,1.725-2.696,3.989-2.696,6.729
				c0,7.016,5.547,12.725,12.365,12.725c3.551,0,6.393-1.754,8.525-3.403V20.556z M11.349,11.502
				c-0.018-0.034-0.037-0.068-0.055-0.104c0,0,0,0,0-0.001c0.014,0.028,0.03,0.051,0.044,0.078
				C11.342,11.483,11.345,11.494,11.349,11.502z M4.933,15.448c-0.056-0.136-0.102-0.278-0.154-0.417
				c0.007,0.018,0.015,0.034,0.022,0.052C4.846,15.204,4.884,15.329,4.933,15.448z M4.52,14.297c-0.04-0.133-0.069-0.269-0.106-0.404
				c0.005,0.02,0.012,0.04,0.018,0.06C4.463,14.067,4.486,14.185,4.52,14.297z M4.218,13.102c-0.028-0.141-0.043-0.285-0.067-0.427
				c0.003,0.016,0.006,0.031,0.009,0.047C4.181,12.848,4.193,12.977,4.218,13.102z M4.032,11.869
				c-0.005-0.051-0.005-0.103-0.009-0.154c0.001,0.011,0.002,0.021,0.003,0.032C4.03,11.787,4.028,11.829,4.032,11.869z
				 M197.44,14.854c0,1.372,0.824,3.046,2.397,3.046c0.699,0,0.973-0.3,1.547-0.65c-0.599-1.148-0.524-4.992-1.122-6.739
				C198.614,11.385,197.44,12.932,197.44,14.854z M201.069,17.153c-0.049,0.032-0.096,0.063-0.142,0.093
				c-0.366,0.243-0.608,0.403-1.09,0.403c-1.481,0-2.147-1.635-2.147-2.796c0-1.622,0.879-3.056,2.423-3.973
				c0.221,0.819,0.336,1.986,0.448,3.119C200.687,15.263,200.805,16.462,201.069,17.153z M118.293,4.747l0.179,0.06l0.106-0.156
				l2.815-4.149l0.265-0.39h-0.472h-3.29h-0.351l0.115,0.332c0.004,0.013,0.459,1.422-0.548,3.622l-0.116,0.254l0.265,0.088
				L118.293,4.747z M117.897,0.36h3.29l-2.815,4.149l-1.032-0.342C118.408,1.835,117.897,0.36,117.897,0.36z M275.525,11.563
				c-1.348-0.697-2.878-1.131-4.357-1.551l-0.409-0.116c-0.751-0.215-3.038-0.868-3.038-1.906c0-1.236,1.475-1.299,1.771-1.299
				c0.997,0,1.872,0.561,2.645,1.056c0.6,0.384,1.117,0.716,1.6,0.716h2.246h0.633l-0.463-0.433c-0.312-0.292-0.552-0.665-0.806-1.06
				c-0.343-0.533-0.697-1.084-1.253-1.439l-0.103-0.065l-0.115,0.04c-0.455,0.16-0.761,0.236-1.265,0.236
				c-0.453,0-0.918-0.031-1.368-0.062c-0.458-0.031-0.932-0.063-1.402-0.063c-2.079,0-4.843,0.383-4.843,3.695
				c0,2.904,2.902,3.849,5.462,4.683c2.106,0.686,3.924,1.279,3.924,2.73c0,0.883-1.143,1.747-1.996,1.747
				c-1.225,0-3.108-1.145-4.483-1.981c-0.276-0.168-0.532-0.323-0.758-0.455l-0.058-0.034h-0.067h-2.447h-0.444l0.23,0.38
				c0.327,0.539,0.682,1.066,1.025,1.575c0.344,0.509,0.699,1.036,1.021,1.57l0.087,0.143l0.165-0.025
				c1.115-0.171,2.004-0.247,2.883-0.247c0.344,0,0.716,0.063,1.076,0.123c0.371,0.062,0.754,0.127,1.122,0.127
				c2.487,0,5.392-1.183,5.392-4.519C277.131,13.327,276.591,12.128,275.525,11.563z M271.739,19.397c-0.699,0-1.474-0.25-2.198-0.25
				c-0.998,0-1.946,0.101-2.921,0.25c-0.649-1.074-1.397-2.071-2.047-3.145h2.447c1.372,0.798,3.818,2.47,5.367,2.47
				c0.973,0,2.246-0.947,2.246-1.997c0-3.57-9.386-2.396-9.386-7.414c0-2.871,2.147-3.445,4.593-3.445c0.923,0,1.847,0.125,2.77,0.125
				c0.524,0,0.848-0.075,1.348-0.25c0.899,0.574,1.249,1.748,2.023,2.472h-2.246c-0.973,0-2.397-1.773-4.245-1.773
				c-0.973,0-2.021,0.425-2.021,1.549c0,1.223,2.345,1.896,3.22,2.146c1.573,0.449,3.27,0.899,4.718,1.648
				c1.223,0.649,1.473,2.097,1.473,3.344C276.881,18.099,274.409,19.397,271.739,19.397z M261.756,17.181V5.917V5.586l-0.318,0.09
				c-0.25,0.071-0.502,0.146-0.754,0.222c-1.054,0.316-2.144,0.643-3.221,0.643h-0.404l0.181,0.362
				c0.671,1.343,0.947,1.896,0.947,3.358v6.765c0,0.763-0.456,1.243-1.193,1.769l0.145,0.453h5.666h0.604l-0.427-0.427
				C262.448,18.289,261.988,17.797,261.756,17.181z M257.138,18.998c0.699-0.5,1.298-1.049,1.298-1.972v-6.765
				c0-1.523-0.3-2.122-0.973-3.469c1.372,0,2.72-0.5,4.044-0.874v11.308c0.25,0.699,0.774,1.249,1.298,1.773H257.138z M259.733,4.42
				c1.035,0,2.222-0.227,2.222-1.998C261.956,1.041,261,0,259.733,0c-1.026,0-2.196,1.184-2.196,2.222
				C257.537,3.578,258.379,4.42,259.733,4.42z M259.733,0.25c1.223,0,1.972,1.023,1.972,2.172c0,1.348-0.699,1.748-1.972,1.748
				c-1.223,0-1.946-0.725-1.946-1.948C257.787,1.324,258.835,0.25,259.733,0.25z M253.798,21.134h-3.11
				c-2.947,1.506-5.927,2.75-8.972,4.028h-2.706l0.005-15.695c0-0.623-0.015-1.265-0.003-1.901c0.003-0.155,0.01-0.31,0.018-0.464
				c0.006-0.122,0.01-0.245,0.019-0.366c0.013-0.176,0.037-0.349,0.059-0.523c0.014-0.105,0.023-0.212,0.04-0.315
				c0.016-0.097,0.045-0.189,0.065-0.284c0.043-0.201,0.088-0.4,0.151-0.593c0.042-0.131,0.1-0.255,0.153-0.381
				c0.055-0.132,0.107-0.265,0.174-0.392c0.083-0.156,0.187-0.304,0.291-0.451c0.054-0.076,0.098-0.155,0.158-0.228
				c0.178-0.216,0.379-0.423,0.617-0.613l-1.361-1.361c-0.005,0-0.01,0-0.014,0c0.003-0.003,0.005-0.006,0.008-0.008
				c-1.284,0-2.649-0.054-4.006-0.054c-1.237,0-2.467,0.045-3.622,0.217c0.294,0.427,0.606,0.984,0.844,1.565
				c0.237,0.581,0.4,1.186,0.4,1.709v12.337l-0.002,6.562c0,0.159-0.031,0.33-0.073,0.504c-0.148,0.595-0.503,1.243-0.845,1.782
				c-0.115,0.178-0.228,0.347-0.331,0.493l1.361,1.361h13.332c1.326,0,2.692,0.276,4.008,0.592c0.439,0.105,0.872,0.215,1.296,0.32h0
				c0.491-0.982,0.857-2.021,1.278-3.045c0.128-0.289,0.278-0.58,0.442-0.87c0.232-0.411,0.495-0.818,0.761-1.215
				c0.08-0.119,0.159-0.238,0.239-0.355c0.231-0.336,0.464-0.672,0.679-0.983l-1.359-1.36
				C253.793,21.142,253.796,21.138,253.798,21.134z M253.293,21.427c-0.651,0.939-1.388,2.003-1.852,3.048
				c-0.14,0.335-0.273,0.673-0.406,1.012c-0.241,0.611-0.489,1.241-0.776,1.839l-0.119-0.03c-1.652-0.416-3.36-0.845-5.049-0.845
				h-12.861c0.498-0.771,1.028-1.743,1.028-2.534V5.024c0-0.951-0.507-2.17-1.069-3.08c0.893-0.11,1.922-0.162,3.197-0.162
				c0.686,0,1.374,0.014,2.053,0.028c0.446,0.009,0.887,0.018,1.322,0.023c-1.405,1.51-1.38,3.643-1.358,5.545
				c0.003,0.243,0.006,0.485,0.006,0.724v17.061v0.25h0.25h4.06h0.05l0.046-0.019l0.215-0.09c3.009-1.263,5.853-2.456,8.721-3.919
				h2.573L253.293,21.427z M219.957,18.649c-0.437-0.432-0.753-0.744-0.753-1.349c0-1.506,0.012-3.019,0.024-4.535
				c0.012-1.523,0.024-3.05,0.024-4.576c0-1.753-2.022-2.722-3.419-2.722c-1.19,0-2.739,0.714-3.62,1.177V5.492v-0.25h-0.25h-0.673
				h-0.055l-0.05,0.023c-1.006,0.467-2.824,0.877-3.89,0.877h-0.542l0.352,0.412l0.245,0.282c0.583,0.666,0.968,1.107,0.968,2.052
				v8.312c0,0.808-0.273,1.241-1.007,1.596l0.109,0.475h5.667l0.133-0.462c-0.677-0.425-1.007-0.952-1.007-1.61V9.362
				c0-0.687,0.897-1.597,1.573-1.597c1.239,0,1.398,0.453,1.398,0.848c0,0.115-0.117,0.759-0.162,0.896l-0.013,0.039v0.041
				c0,0.791,0.051,1.592,0.1,2.368c0.049,0.777,0.1,1.581,0.1,2.375c0,1.548-0.023,3.063-0.296,4.573l-0.053,0.294h0.299h4.744h0.604
				l-0.427-0.427L219.957,18.649z M215.159,18.948c0.276-1.523,0.3-3.07,0.3-4.618c0-1.598-0.2-3.171-0.2-4.743
				c0.05-0.151,0.175-0.824,0.175-0.975c0-0.949-0.899-1.098-1.648-1.098c-0.824,0-1.823,1.023-1.823,1.847v7.839
				c0,0.824,0.449,1.398,1.124,1.821h-5.667c0.824-0.399,1.148-0.923,1.148-1.821V8.888c0-1.174-0.548-1.648-1.273-2.496
				c1.124,0,2.971-0.425,3.995-0.9h0.673v1.573c1.023-0.574,2.671-1.348,3.87-1.348c1.348,0,3.169,0.949,3.169,2.472
				c0,3.044-0.049,6.09-0.049,9.11c0,0.774,0.449,1.148,0.949,1.648H215.159z M204.365,12.072c-0.207-1.741-0.402-3.385-0.771-4.463
				c-0.45-1.305-1.638-1.966-3.531-1.966c-3.204,0-5.032,1.168-5.929,3.788l-0.222,0.647l0.587-0.352
				c0.123-0.074,0.271-0.166,0.438-0.27c0.788-0.492,2.107-1.316,2.705-1.316c0.988,0,1.704,0.363,2.178,1.109
				c-1.873,0.66-4.878,2.068-6.049,4.576l-0.026,0.058c-0.21,0.469-0.497,1.112-0.497,1.621c0,2.754,2.437,4.195,4.843,4.195
				c1.089,0,2.121-0.2,3.339-0.649c0.538,0.003,1.104,0.062,1.652,0.119c0.582,0.06,1.184,0.123,1.773,0.123
				c0.422,0,0.796-0.032,1.145-0.097L206.5,19.1l-0.38-0.339C205.045,17.802,204.683,14.758,204.365,12.072z M204.854,19.04
				c-1.149,0-2.337-0.242-3.469-0.242c-1.074,0.399-2.122,0.649-3.294,0.649c-2.347,0-4.593-1.374-4.593-3.945
				c0-0.474,0.299-1.122,0.5-1.573c1.072-2.295,3.868-3.769,6.189-4.543c-0.524-1.023-1.397-1.497-2.545-1.497
				c-0.8,0-2.522,1.173-3.271,1.622c0.949-2.77,2.897-3.619,5.693-3.619c1.372,0,2.795,0.349,3.294,1.797
				c0.949,2.77,0.749,9.61,2.597,11.258C205.594,19.015,205.226,19.04,204.854,19.04z M254.798,17.374l0.024-10.043l0.001-0.348
				l-0.33,0.11c-1.352,0.451-2.919,0.912-4.289,0.912h-0.484L250,8.4c0.083,0.117,0.172,0.234,0.263,0.352
				c0.351,0.459,0.714,0.933,0.714,1.476v5.376c0,0.719-1.051,1.197-1.747,1.197c-0.833,0-1.547-0.974-1.547-1.771V5.742V5.5
				l-0.241-0.008l-0.139-0.002c-0.714,0-1.433,0.185-2.129,0.365c-0.691,0.178-1.406,0.362-2.11,0.362h-0.39l0.163,0.355
				c0.107,0.233,0.269,0.464,0.439,0.708c0.276,0.395,0.561,0.803,0.561,1.16v8.387c0,1.635,2.164,2.72,3.596,2.72
				c1.108,0,2.538-0.425,3.544-0.894v0.295v0.25h0.25h4.468l0.142-0.456c-0.103-0.071-0.199-0.134-0.29-0.194
				C255.017,18.2,254.798,18.056,254.798,17.374z M251.228,18.948v-0.699c-1.023,0.55-2.645,1.048-3.794,1.048
				c-1.299,0-3.346-0.998-3.346-2.47V8.439c0-0.675-0.748-1.374-1.023-1.972c1.453,0,2.857-0.727,4.239-0.727
				c0.044,0,0.087,0.001,0.13,0.002v9.287c0,0.923,0.824,2.021,1.797,2.021c0.848,0,1.997-0.574,1.997-1.447v-5.376
				c0-0.775-0.599-1.374-1.023-1.972c1.422,0,3.02-0.475,4.368-0.924l-0.024,10.044c0,0.949,0.425,1.074,1.148,1.573H251.228z
				 M147.447,15.06c0.506-0.185,1.029-0.438,1.029-1.045V9.244c0-0.439-0.54-1.181-1.029-1.181c-1.433,0-1.517,1.77-1.517,2.833
				c0,1.011,0.101,2.108,0.539,3.018C146.621,14.234,147.025,15.06,147.447,15.06z M147.447,8.313c0.321,0,0.779,0.602,0.779,0.931
				v4.771c0,0.397-0.308,0.601-0.81,0.79c-0.089-0.026-0.33-0.176-0.721-0.998c-0.351-0.73-0.515-1.654-0.515-2.91
				C146.18,9.11,146.571,8.313,147.447,8.313z M120.522,17.204V5.752l-0.159,0.045c-0.251,0.07-0.503,0.146-0.756,0.222
				c-1.062,0.318-2.16,0.647-3.254,0.647l-0.201,0.003l0.091,0.181c0.678,1.356,0.959,1.918,0.959,3.41v6.766
				c0,0.814-0.498,1.336-1.245,1.87l0.072,0.227h5.968l-0.214-0.214C121.256,18.381,120.762,17.854,120.522,17.204z M116.406,18.872
				c0.549-0.431,1.046-0.981,1.046-1.847V10.26c0-1.481-0.282-2.111-0.898-3.348c1.062-0.035,2.11-0.35,3.126-0.653
				c0.198-0.06,0.396-0.119,0.593-0.176v11.142l0.007,0.042c0.222,0.618,0.645,1.12,1.116,1.605H116.406z M126.984,14.854
				c0,1.372,0.822,3.046,2.396,3.046c0.699,0,0.973-0.3,1.547-0.65c-0.599-1.148-0.524-4.992-1.122-6.739
				C128.157,11.385,126.984,12.932,126.984,14.854z M130.611,17.153c-0.049,0.032-0.096,0.063-0.142,0.093
				c-0.366,0.243-0.608,0.403-1.09,0.403c-1.48,0-2.146-1.635-2.146-2.796c0-1.623,0.878-3.056,2.422-3.973
				c0.221,0.82,0.337,1.987,0.448,3.119C130.229,15.263,130.348,16.462,130.611,17.153z M34.911,11.159V10.51
				c0-0.175-0.649-1.823-0.799-2.097c-0.274-0.599-0.724-0.949-1.398-0.949c-1.797,0-1.947,1.074-1.947,2.597
				c0,0.25-0.149,0.449-0.2,0.673c0.025,0.151,0.1,0.3,0.2,0.425H34.911z M30.888,10.574c0.061-0.146,0.129-0.311,0.129-0.513
				c0-1.655,0.236-2.347,1.697-2.347c0.54,0,0.923,0.263,1.171,0.803c0.145,0.266,0.748,1.805,0.777,2.009l-0.001,0.383h-3.764
				c-0.033-0.056-0.058-0.113-0.073-0.169C30.841,10.684,30.865,10.63,30.888,10.574z M157.441,10.693v-0.438
				c0-0.118-0.438-1.231-0.539-1.416c-0.186-0.404-0.49-0.641-0.944-0.641c-1.214,0-1.316,0.725-1.316,1.754
				c0,0.169-0.101,0.303-0.135,0.455c0.016,0.102,0.068,0.203,0.135,0.287H157.441z M154.799,10.329
				c0.042-0.1,0.093-0.225,0.093-0.378c0-1.128,0.154-1.504,1.066-1.504c0.334,0,0.561,0.157,0.717,0.495
				c0.096,0.178,0.491,1.184,0.518,1.336l-0.001,0.164h-2.413c-0.005-0.011-0.009-0.021-0.013-0.032
				C154.776,10.384,154.787,10.357,154.799,10.329z M133.907,12.072c-0.207-1.741-0.402-3.385-0.771-4.463
				c-0.45-1.305-1.638-1.966-3.531-1.966c-3.204,0-5.032,1.168-5.929,3.788l-0.222,0.647l0.587-0.352
				c0.123-0.074,0.271-0.166,0.437-0.27c0.788-0.492,2.107-1.316,2.705-1.316c0.988,0,1.704,0.363,2.178,1.109
				c-1.873,0.66-4.878,2.068-6.049,4.576l-0.026,0.058c-0.21,0.469-0.497,1.112-0.497,1.621c0,2.754,2.437,4.195,4.843,4.195
				c1.089,0,2.121-0.2,3.339-0.649c0.538,0.003,1.104,0.062,1.652,0.119c0.582,0.06,1.184,0.123,1.773,0.123
				c0.422,0,0.796-0.032,1.145-0.097l0.501-0.093l-0.38-0.339C134.588,17.802,134.226,14.758,133.907,12.072z M134.396,19.04
				c-1.149,0-2.337-0.242-3.469-0.242c-1.074,0.399-2.122,0.649-3.294,0.649c-2.347,0-4.593-1.374-4.593-3.945
				c0-0.474,0.299-1.122,0.5-1.573c1.072-2.295,3.868-3.769,6.189-4.543c-0.524-1.023-1.397-1.497-2.545-1.497
				c-0.8,0-2.522,1.173-3.271,1.622c0.949-2.77,2.897-3.619,5.693-3.619c1.372,0,2.795,0.349,3.294,1.797
				c0.949,2.77,0.749,9.61,2.597,11.258C135.136,19.015,134.768,19.04,134.396,19.04z M146.537,16.49c1.038,0,1.332-0.159,1.954-0.541
				l0.07,0.125l0.071,0.129h0.147h2.664h0.588l-0.407-0.423c-0.426-0.443-0.673-0.802-0.673-1.327V3.14V2.772l-0.342,0.136
				c-0.962,0.381-1.914,0.638-2.754,0.744l-0.449,0.056l0.287,0.35c0.253,0.308,0.422,0.624,0.617,0.989l0.051,0.095v2.2
				c-0.468-0.25-0.871-0.439-1.52-0.439c-2.495,0-3.841,2.587-3.841,5.022C142.999,14.031,143.926,16.49,146.537,16.49z M146.84,7.153
				c0.742,0,1.129,0.269,1.77,0.607V5.079c-0.237-0.438-0.422-0.81-0.725-1.18c0.944-0.118,1.922-0.405,2.815-0.759v11.312
				c0,0.641,0.321,1.062,0.743,1.501h-2.664l-0.169-0.303l0.039-0.09c-0.844,0.512-1.004,0.681-2.112,0.681
				c-2.395,0-3.287-2.26-3.287-4.316C143.249,9.799,144.379,7.153,146.84,7.153z M112.315,5.468c-1.613,0-2.219,0.671-2.987,1.521
				c-0.108,0.119-0.217,0.241-0.333,0.365V5.518V5.08l-0.377,0.223c-1.006,0.595-2.622,1.289-3.866,1.289h-0.397l0.172,0.358
				c0.057,0.118,0.123,0.249,0.194,0.388c0.292,0.574,0.655,1.288,0.655,1.775v7.813c0,0.743-0.333,1.345-0.989,1.791l0.141,0.457
				h5.592l0.077-0.488c-0.797-0.258-1.201-0.784-1.201-1.561v-5.79c0-0.663,1.176-1.972,1.773-1.972c0.211,0,0.383,0.062,0.601,0.14
				c0.321,0.115,0.721,0.259,1.396,0.259c1.344,0,2.247-0.913,2.247-2.271C115.011,6.101,113.613,5.468,112.315,5.468z M112.764,9.511
				c-1.098,0-1.398-0.399-1.997-0.399c-0.774,0-2.023,1.448-2.023,2.222v5.79c0,0.924,0.524,1.523,1.374,1.799h-5.592
				c0.699-0.475,1.098-1.148,1.098-1.998V9.112c0-0.673-0.574-1.648-0.873-2.271c1.272,0,2.895-0.675,3.993-1.323v2.446
				c1.298-1.247,1.622-2.246,3.57-2.246c1.074,0,2.446,0.474,2.446,1.773C114.761,8.687,114.011,9.511,112.764,9.511z M155.66,23.164
				c-1.058,0-2.135-0.507-3.626-1.208l-0.158-0.074c-2.072-0.991-3.123-1.425-4.494-1.425c-1.1,0-2.052,0.386-2.754,1.116
				c-0.85,0.884-1.265,2.214-1.201,3.844l0.5-0.002c0.057-1.712,2.462-2.403,3.454-2.403c0.92,0,1.874,0.475,3.082,1.076l0.541,0.268
				c2.308,1.069,3.387,1.394,4.623,1.394c1.101,0,2.035-0.368,2.701-1.065c0.825-0.863,1.205-2.174,1.128-3.896l-0.5,0.006
				C158.927,22.358,156.632,23.164,155.66,23.164z M155.627,25.499c-1.244,0-2.366-0.374-4.513-1.369
				c-1.462-0.716-2.613-1.369-3.733-1.369s-3.642,0.779-3.704,2.645c-0.123-3.112,1.557-4.699,3.704-4.699
				c1.306,0,2.303,0.404,4.388,1.401c1.587,0.746,2.738,1.306,3.89,1.306c1.12,0,3.515-0.902,3.547-2.615
				C159.362,24.286,157.556,25.499,155.627,25.499z M148.779,15.481c-0.045,0.027-0.089,0.053-0.13,0.078l0.012-0.028L148.779,15.481z
				 M158.908,14.725c-1.773,0-3.184-1.103-3.914-3.04h5.094h0.25v-0.25v-0.893v-0.051l-0.02-0.047
				c-0.675-1.578-1.516-3.541-3.77-3.541c-2.581,0-4.38,2.54-4.38,4.819c0,2.621,2.214,4.835,4.836,4.835
				c1.454,0,2.81-0.655,3.719-1.797l-0.279-0.391C159.971,14.536,159.437,14.725,158.908,14.725z M157.003,16.307
				c-2.495,0-4.586-2.09-4.586-4.585c0-2.242,1.753-4.569,4.13-4.569c2.057,0,2.833,1.736,3.54,3.389v0.893h-5.445
				c0.641,1.989,2.057,3.54,4.266,3.54c0.557,0,1.095-0.185,1.619-0.37C159.667,15.684,158.369,16.307,157.003,16.307z M75.035,11.159
				V10.51c0-0.175-0.649-1.823-0.799-2.097c-0.274-0.599-0.724-0.949-1.398-0.949c-1.797,0-1.947,1.074-1.947,2.597
				c0,0.25-0.149,0.449-0.2,0.673c0.025,0.151,0.1,0.3,0.2,0.425H75.035z M71.012,10.574c0.061-0.146,0.129-0.311,0.129-0.513
				c0-1.655,0.236-2.347,1.697-2.347c0.54,0,0.923,0.263,1.171,0.803c0.145,0.266,0.748,1.805,0.777,2.009l-0.001,0.383h-3.764
				c-0.033-0.056-0.058-0.113-0.073-0.169C70.965,10.684,70.989,10.63,71.012,10.574z M61.979,17.12
				c0.076-0.319,0.363-1.124,0.525-1.581c0.091-0.256,0.165-0.465,0.195-0.56c1.027-2.887,3.372-8.395,5.275-10.841l0.314-0.404
				h-0.511h-5.017h-0.481l0.276,0.394c0.522,0.744,1.053,2.322,1.053,3.126c0,1.313-1.565,6.089-2.556,7.574
				c-0.825-1.204-3.01-6.382-3.01-7.238L58.04,7.322c-0.005-0.493-0.005-0.493,0.432-0.676l0.294-0.125l-0.1-0.479h-5.892h-0.604
				l0.427,0.427c1.597,1.597,3.117,5.57,4.122,8.199c0.131,0.343,0.253,0.662,0.366,0.95c0.108,0.287,0.215,0.587,0.325,0.892
				c0.426,1.186,0.866,2.412,1.492,3.442l0.111,0.183l0.198-0.081c1.357-0.557,2.434-0.88,3.924-0.88h0.5l-0.299-0.4
				C62.932,18.236,62.49,17.665,61.979,17.12z M59.115,19.822c-0.774-1.273-1.273-2.897-1.797-4.295
				c-0.974-2.496-2.696-7.388-4.544-9.235h5.892c-0.974,0.425-0.874,0.25-0.874,1.298c0,0.975,2.572,7.039,3.271,7.639
				c0.998-1.148,2.795-6.478,2.795-7.975c0-0.899-0.574-2.522-1.099-3.27h5.017c-1.922,2.47-4.268,7.975-5.316,10.92
				c-0.101,0.324-0.724,1.972-0.749,2.297c0.525,0.548,0.974,1.122,1.423,1.722C61.611,18.923,60.513,19.248,59.115,19.822z
				 M49.086,5.468c-1.613,0-2.219,0.671-2.987,1.521c-0.108,0.119-0.217,0.241-0.333,0.365V5.518V5.08l-0.377,0.223
				c-1.006,0.595-2.622,1.289-3.866,1.289h-0.397l0.172,0.358c0.057,0.118,0.123,0.249,0.193,0.387
				c0.292,0.574,0.655,1.288,0.655,1.776v7.813c0,0.743-0.333,1.346-0.989,1.791l0.141,0.457h5.592l0.077-0.488
				c-0.797-0.258-1.201-0.784-1.201-1.561v-5.79c0-0.663,1.176-1.972,1.773-1.972c0.211,0,0.383,0.062,0.601,0.14
				c0.321,0.115,0.721,0.259,1.396,0.259c1.344,0,2.247-0.913,2.247-2.271C51.782,6.101,50.385,5.468,49.086,5.468z M49.536,9.511
				c-1.098,0-1.398-0.399-1.997-0.399c-0.774,0-2.023,1.448-2.023,2.222v5.79c0,0.924,0.524,1.523,1.374,1.799h-5.592
				c0.699-0.475,1.098-1.148,1.098-1.998V9.112c0-0.673-0.574-1.648-0.873-2.271c1.272,0,2.895-0.675,3.993-1.323v2.446
				c1.298-1.247,1.622-2.246,3.57-2.246c1.074,0,2.446,0.474,2.446,1.773C51.532,8.687,50.783,9.511,49.536,9.511z M37.083,17.249
				c-2.728,0-4.888-1.723-5.967-4.742h7.715h0.25v-0.25v-1.322v-0.051l-0.02-0.047c-0.987-2.303-2.215-5.17-5.472-5.17
				c-3.752,0-6.366,3.697-6.366,7.015c0,3.816,3.224,7.039,7.04,7.039c2.115,0,4.088-0.953,5.413-2.615l-0.279-0.391
				C38.689,16.966,37.889,17.249,37.083,17.249z M34.262,19.472c-3.695,0-6.79-3.095-6.79-6.789c0-3.32,2.597-6.765,6.116-6.765
				c3.045,0,4.194,2.571,5.242,5.018v1.322h-8.063c0.949,2.945,3.046,5.242,6.316,5.242c0.824,0,1.623-0.274,2.396-0.548
				C38.206,18.549,36.284,19.472,34.262,19.472z M77.207,17.249c-2.728,0-4.888-1.723-5.967-4.742h7.714h0.25v-0.25v-1.322v-0.051
				l-0.02-0.047c-0.986-2.303-2.213-5.17-5.471-5.17c-3.752,0-6.366,3.697-6.366,7.015c0,3.816,3.224,7.039,7.04,7.039
				c2.116,0,4.089-0.953,5.413-2.615l-0.279-0.392C78.813,16.966,78.011,17.249,77.207,17.249z M74.386,19.472
				c-3.695,0-6.79-3.095-6.79-6.789c0-3.32,2.597-6.765,6.116-6.765c3.045,0,4.194,2.571,5.241,5.018v1.322h-8.063
				c0.949,2.945,3.046,5.242,6.316,5.242c0.824,0,1.622-0.274,2.397-0.548C78.331,18.549,76.408,19.472,74.386,19.472z M101.36,17.249
				c-2.728,0-4.888-1.723-5.967-4.742h7.714h0.25v-0.25v-1.322v-0.051l-0.02-0.047c-0.986-2.303-2.213-5.17-5.472-5.17
				c-3.751,0-6.365,3.697-6.365,7.015c0,3.816,3.224,7.039,7.039,7.039c2.116,0,4.089-0.953,5.413-2.615l-0.279-0.392
				C102.966,16.966,102.164,17.249,101.36,17.249z M98.539,19.472c-3.695,0-6.789-3.095-6.789-6.789c0-3.32,2.595-6.765,6.115-6.765
				c3.046,0,4.194,2.571,5.242,5.018v1.322h-8.063c0.949,2.945,3.046,5.242,6.316,5.242c0.824,0,1.622-0.274,2.397-0.548
				C102.484,18.549,100.562,19.472,98.539,19.472z M99.188,11.159V10.51c0-0.175-0.649-1.823-0.798-2.097
				c-0.276-0.599-0.725-0.949-1.398-0.949c-1.797,0-1.948,1.074-1.948,2.597c0,0.25-0.149,0.449-0.2,0.673
				c0.024,0.151,0.101,0.3,0.2,0.425H99.188z M95.165,10.574c0.061-0.146,0.129-0.311,0.129-0.513c0-1.655,0.236-2.347,1.698-2.347
				c0.539,0,0.922,0.263,1.171,0.803c0.15,0.276,0.746,1.797,0.776,2.009l0,0.383h-3.764c-0.034-0.056-0.059-0.114-0.073-0.168
				C95.118,10.684,95.142,10.63,95.165,10.574z M90.543,17.028c-0.271,0.143-0.421,0.221-0.934,0.221
				c-3.224,0-6.092-3.657-6.092-6.539c0-1.726,0.664-2.885,1.972-3.445h0.858c-0.333,0.646-0.484,1.045-0.484,1.273
				c0,1.177,1.257,2.422,2.446,2.422c1.24,0,2.621-0.913,2.621-2.222c0-2.271-2.628-3.095-4.394-3.095
				c-3.988,0-6.564,2.685-6.564,6.84c0,2.935,1.407,5.301,3.86,6.491c0.821,0.382,1.628,0.724,2.505,0.724
				c2.002,0,3.917-0.969,4.998-2.529l-0.3-0.374C90.824,16.88,90.673,16.959,90.543,17.028z M86.337,19.447
				c-0.848,0-1.646-0.35-2.396-0.699c-2.472-1.199-3.719-3.594-3.719-6.266c0-3.894,2.345-6.59,6.314-6.59
				c1.722,0,4.144,0.774,4.144,2.845c0,1.199-1.322,1.972-2.371,1.972c-1.048,0-2.196-1.122-2.196-2.172
				c0-0.324,0.5-1.247,0.649-1.523h-1.323c-1.572,0.649-2.172,2.047-2.172,3.695c0,3.145,3.096,6.789,6.342,6.789
				c0.822,0,0.848-0.2,1.521-0.474C90.058,18.573,88.21,19.447,86.337,19.447z"/>
		</g>
	</svg>
)

export default SanLuisLogoWide;
