import React from 'react';

const SanLuisLogo = () => (
	<svg className="san-luis-logo" width="126.811px" height="52.417px" viewBox="0 0 126.811 52.417">
		<path className="fill" fill="#E2001D" d="M5.518,34.573c-0.467-0.252-0.836-0.451-1.456-0.452c-2.211,0-3.405,2.307-3.406,4.478
			c-0.001,1.057,0.246,2.03,0.697,2.74c0.55,0.867,1.388,1.325,2.424,1.326h0.009c0.948,0,1.178-0.141,1.789-0.517l0.023-0.015
			l0.15,0.271l2.804,0.003l-0.207-0.216c-0.405-0.423-0.64-0.768-0.64-1.282l0.01-10.507L7.54,30.473
			c-0.89,0.351-1.768,0.586-2.539,0.682l-0.229,0.028l0.146,0.178c0.236,0.29,0.393,0.581,0.573,0.918l0.061,0.079L5.55,34.59
			L5.518,34.573z M5.421,40.509c-0.001,0.434-0.328,0.641-0.812,0.827c-0.097-0.001-0.365-0.096-0.776-0.977
			c-0.327-0.679-0.479-1.536-0.479-2.697c0.002-1.144,0.146-2.456,1.26-2.456h0.001c0.133,0,0.29,0.084,0.442,0.237
			c0.209,0.209,0.366,0.516,0.366,0.713L5.421,40.509z M14.756,41.267c-1.704-0.002-3.049-1.085-3.711-2.979l4.917,0.003L15.953,37.3
			c-0.635-1.486-1.354-3.17-3.347-3.172c-2.294,0-3.895,2.263-3.896,4.293c-0.001,1.135,0.451,2.213,1.272,3.036
			c0.822,0.823,1.899,1.276,3.035,1.276h0.003c1.294,0,2.502-0.582,3.313-1.598l0.277-0.346l-0.419,0.146
			C15.752,41.091,15.253,41.267,14.756,41.267z M10.87,37.251c0.011-0.047,0.03-0.091,0.049-0.136
			c0.037-0.088,0.078-0.188,0.078-0.308c0.001-0.916,0.074-1.473,1.071-1.473v-0.127l0.002,0.127c0.345,0.001,0.589,0.168,0.75,0.519
			c0.087,0.161,0.46,1.118,0.475,1.232v0.272l-2.36-0.002C10.901,37.308,10.878,37.263,10.87,37.251z M60.814,33.694
			c-0.215-1.827-0.419-3.554-0.802-4.677c-0.453-1.315-1.662-1.983-3.609-1.984c-3.348,0-5.177,1.166-6.115,3.899l-0.113,0.329
			l0.757-0.462c0.845-0.526,2.259-1.408,2.929-1.408c1.141,0.001,1.981,0.467,2.502,1.384c-1.941,0.662-5.219,2.137-6.495,4.868
			c-0.216,0.483-0.511,1.145-0.512,1.65c-0.002,2.816,2.498,4.291,4.969,4.293h0.007c1.147,0,2.231-0.212,3.47-0.682
			c0.589,0.001,1.206,0.065,1.804,0.128c0.612,0.064,1.246,0.131,1.862,0.131c0.435,0,0.82-0.033,1.179-0.1l0.255-0.047l-0.193-0.173
			C61.534,39.798,61.152,36.556,60.814,33.694z M57.396,39.255c-0.393,0.259-0.676,0.446-1.227,0.446
			c-0.904-0.001-1.468-0.549-1.781-1.009C54,38.123,53.77,37.347,53.77,36.613c0.002-1.788,1.038-3.421,2.779-4.395
			c0.27,0.883,0.399,2.207,0.524,3.489c0.137,1.389,0.266,2.705,0.564,3.39C57.552,39.151,57.473,39.204,57.396,39.255z
			 M76.784,40.729l0.349,0.35l-5.463-0.005l0.027-0.149c0.291-1.607,0.316-3.277,0.317-4.85c0.001-0.844-0.053-1.695-0.104-2.52
			c-0.051-0.814-0.104-1.656-0.103-2.485c0.055-0.188,0.186-0.883,0.186-1.027c0-0.4-0.164-0.675-0.501-0.841
			c-0.264-0.129-0.626-0.192-1.11-0.192c-0.788,0-1.796,1.021-1.797,1.82l-0.006,8.271c0,0.743,0.366,1.337,1.123,1.815l0.372,0.235
			L63.1,41.147l0.5-0.241c0.82-0.396,1.141-0.903,1.142-1.808l0.007-8.771c0-1.048-0.42-1.529-1.055-2.257l-0.436-0.506l0.276,0.001
			c1.143,0,3.086-0.437,4.162-0.933l0.894-0.012l-0.002,1.57c0.864-0.47,2.628-1.33,3.953-1.33c0.767,0,1.618,0.271,2.279,0.725
			c0.771,0.528,1.193,1.243,1.192,2.013c-0.001,1.61-0.016,3.221-0.03,4.828c-0.015,1.6-0.029,3.196-0.03,4.785
			C75.953,39.906,76.319,40.27,76.784,40.729z M105.002,40.666l0.641,0.436l-5.25-0.005v-0.655c-1.048,0.522-2.652,1.021-3.875,1.021
			h-0.003c-1.455-0.001-3.653-1.093-3.652-2.737l0.007-8.851c0-0.418-0.312-0.865-0.615-1.299c-0.178-0.254-0.345-0.494-0.453-0.729
			l-0.082-0.181l0.198,0.001c0.763,0,1.524-0.196,2.26-0.385c0.724-0.187,1.473-0.379,2.206-0.379l0.271,0.007l-0.008,9.922
			c0,0.448,0.199,0.953,0.533,1.351c0.351,0.417,0.801,0.656,1.236,0.657c0.807,0,1.979-0.545,1.98-1.398l0.004-5.473
			c0-0.615-0.395-1.133-0.776-1.634c-0.096-0.126-0.19-0.25-0.278-0.374l-0.141-0.201l0.245,0.001c1.469,0,3.134-0.488,4.569-0.966
			l0.168-0.056l-0.037,10.575C104.15,40.106,104.435,40.293,105.002,40.666z M110.62,21.86c0.378,0.444,0.586,1.042,0.585,1.683
			c-0.001,1.38-0.66,1.969-2.204,1.969h-0.005c-1.366-0.001-2.181-0.817-2.18-2.184c0.001-1.03,1.163-2.205,2.183-2.205
			C109.64,21.123,110.216,21.385,110.62,21.86z M112.313,40.942l0.218,0.218l-6.685-0.006l0.323-0.23
			c0.813-0.58,1.318-1.113,1.319-1.976l0.006-7.139c0.001-1.58-0.296-2.173-1.012-3.605l-0.092-0.185l0.206,0.001
			c1.164,0,2.325-0.348,3.448-0.683c0.263-0.079,0.524-0.157,0.786-0.23l0.162-0.046l-0.011,12.1
			C111.222,39.813,111.727,40.355,112.313,40.942z M126.683,36.961c-0.002,3.415-2.991,4.627-5.55,4.627h-0.006
			c-0.376,0-0.773-0.067-1.158-0.132c-0.386-0.065-0.785-0.133-1.16-0.133c-0.948,0-1.889,0.08-3.062,0.259l-0.085,0.013l-0.044-0.073
			c-0.343-0.567-0.712-1.116-1.081-1.665c-0.367-0.545-0.734-1.09-1.076-1.655l-0.116-0.193l2.808,0.003
			c0.302,0.155,0.57,0.319,0.86,0.496c1.465,0.893,3.472,2.115,4.8,2.116c0.979,0,2.245-0.96,2.246-1.979
			c0.001-1.631-2.056-2.303-4.232-3.015c-2.657-0.868-5.668-1.852-5.665-4.815c0.003-3.331,2.654-3.758,4.964-3.758
			c0.504,0,0.995,0.033,1.486,0.066c0.483,0.033,0.967,0.066,1.45,0.067c0.553,0,0.884-0.083,1.38-0.256l0.059-0.021l0.052,0.033
			c0.561,0.358,0.926,0.928,1.278,1.478c0.261,0.406,0.53,0.826,0.872,1.146l0.235,0.221l-2.692-0.003c-0.47,0-1-0.341-1.614-0.735
			c-0.83-0.533-1.771-1.137-2.862-1.138c-0.972,0-2.007,0.396-2.008,1.504c-0.001,1.2,2.485,1.911,3.303,2.146l0.444,0.127
			c1.55,0.441,3.153,0.898,4.556,1.625C126.157,33.898,126.686,35.09,126.683,36.961z M15.487,45.549l0.254-0.003
			c0.073,1.668-0.291,2.934-1.084,3.762c-0.635,0.663-1.526,1.014-2.578,1.014h-0.005c-1.203-0.001-2.258-0.32-4.514-1.369
			l-0.539-0.267c-1.207-0.602-2.161-1.077-3.097-1.078c-1.017,0-3.475,0.717-3.536,2.489l-0.254,0.001
			c-0.062-1.576,0.336-2.858,1.152-3.708c0.671-0.698,1.582-1.066,2.637-1.066c1.331,0.002,2.361,0.428,4.393,1.4l0.174,0.082
			c1.479,0.696,2.548,1.2,3.615,1.201C13.102,48.007,15.454,47.171,15.487,45.549z M31.718,15.786h0.003
			c1.556,0,3.006-0.699,3.979-1.92l-0.142-0.199c-0.531,0.188-1.132,0.399-1.741,0.399c-2.079-0.002-3.718-1.328-4.517-3.647
			l5.946,0.004l-0.009-1.16c-0.764-1.787-1.629-3.812-4.016-3.813c-2.755,0-4.676,2.717-4.678,5.155
			c-0.001,1.363,0.542,2.658,1.528,3.647C29.06,15.24,30.355,15.785,31.718,15.786z M29.16,9.014c0.043-0.103,0.092-0.22,0.092-0.357
			c0.001-1.088,0.094-1.802,1.32-1.802h0.002c0.427,0,0.729,0.207,0.929,0.64c0.12,0.222,0.562,1.374,0.577,1.499V9.35l-2.892-0.003
			c-0.046-0.067-0.077-0.132-0.089-0.161C29.112,9.126,29.136,9.07,29.16,9.014z M37.428,13.771l0.005-5.812
			c0-0.376-0.272-0.914-0.491-1.346L36.71,6.14l0.202,0.001c0.938,0,2.115-0.498,2.907-0.964l0.192-0.113L40.01,6.798
			c0.126-0.131,0.239-0.257,0.348-0.376c0.584-0.646,1.005-1.112,2.179-1.112c0.94,0.001,1.948,0.454,1.947,1.447
			c0,0.989-0.632,1.629-1.611,1.629c-0.494,0-0.786-0.104-1.021-0.189c-0.167-0.061-0.3-0.108-0.466-0.108
			c-0.476,0-1.377,0.998-1.378,1.525l-0.003,4.308c-0.001,0.755,0.507,1.078,0.932,1.217l-0.039,0.248l-4.159-0.004l-0.071-0.232
			C37.17,14.807,37.427,14.342,37.428,13.771z M50.373,16c-0.464-0.764-0.79-1.675-1.106-2.557l-0.239-0.658l-0.264-0.688
			c-0.749-1.967-1.882-4.939-3.081-6.142l-0.218-0.218l4.691,0.005l0.051,0.244l-0.216,0.091c-0.363,0.151-0.363,0.151-0.36,0.558
			l0.001,0.199c-0.001,0.657,1.712,4.706,2.295,5.485c0.746-1.04,1.979-4.789,1.979-5.807c0.001-0.657-0.436-1.848-0.794-2.36
			l-0.14-0.201l4.237,0.004l-0.16,0.205c-1.404,1.805-3.173,5.963-3.957,8.16c-0.021,0.065-0.075,0.22-0.143,0.408
			c-0.136,0.38-0.354,0.992-0.401,1.215c0.397,0.422,0.74,0.866,1.024,1.248l0.152,0.204l-0.254-0.001
			c-1.127,0-1.931,0.24-2.941,0.656l-0.102,0.041L50.373,16z M62.409,15.811h0.003c1.554,0,3.004-0.699,3.979-1.92l-0.142-0.199
			c-0.531,0.188-1.132,0.399-1.74,0.399c-2.079-0.002-3.718-1.328-4.517-3.647l5.946,0.004l-0.01-1.16
			c-0.764-1.787-1.629-3.812-4.015-3.813c-2.755,0-4.676,2.717-4.678,5.155C57.234,13.436,59.602,15.809,62.409,15.811z M59.85,9.043
			c0.044-0.104,0.093-0.223,0.093-0.362c0.001-1.121,0.091-1.802,1.319-1.802V6.752l0.002,0.127c0.429,0.001,0.73,0.208,0.929,0.64
			c0.107,0.199,0.562,1.361,0.578,1.499L62.77,9.374l-2.891-0.003c-0.046-0.067-0.077-0.132-0.09-0.159
			C59.803,9.154,59.827,9.099,59.85,9.043z M67.194,10.488c0.003-3.053,1.896-5.024,4.821-5.024c1.297,0.001,3.215,0.6,3.214,2.246
			c-0.001,0.938-0.996,1.592-1.889,1.592c-0.856-0.001-1.763-0.896-1.763-1.743c0-0.064,0-0.251,0.398-1.005l-0.771-0.001
			c-0.972,0.409-1.489,1.294-1.49,2.619c-0.001,1.094,0.529,2.334,1.417,3.318c0.922,1.021,2.076,1.607,3.167,1.608
			c0.399,0,0.516-0.061,0.726-0.171c0.096-0.051,0.206-0.108,0.361-0.172l0.152,0.19c-0.795,1.144-2.199,1.854-3.666,1.854h-0.006
			c-0.643,0-1.235-0.252-1.835-0.533C68.226,14.391,67.193,12.649,67.194,10.488z M81.509,15.826c1.556,0,3.006-0.699,3.979-1.92
			l-0.142-0.199c-0.531,0.188-1.132,0.399-1.74,0.399c-2.079-0.002-3.718-1.329-4.517-3.647l5.946,0.004l-0.01-1.161
			c-0.764-1.787-1.63-3.812-4.018-3.812c-2.753,0-4.673,2.717-4.675,5.155c-0.001,1.362,0.542,2.658,1.528,3.646
			c0.987,0.989,2.281,1.534,3.645,1.535H81.509z M78.887,9.225c0.014-0.059,0.038-0.115,0.062-0.173
			c0.043-0.103,0.092-0.219,0.092-0.355c0.001-1.121,0.091-1.802,1.318-1.802h0.003c0.429,0,0.73,0.207,0.929,0.64
			c0.107,0.199,0.562,1.361,0.578,1.499L81.868,9.39l-2.891-0.003C78.931,9.319,78.9,9.254,78.887,9.225z M87.216,13.811l0.005-5.812
			c0-0.377-0.272-0.914-0.491-1.345L86.498,6.18l0.202,0.001c0.936,0,2.115-0.499,2.907-0.965l0.191-0.113v1.735
			c0.125-0.131,0.238-0.257,0.346-0.375c0.585-0.646,1.007-1.113,2.18-1.113c0.648,0,1.24,0.206,1.585,0.551
			c0.241,0.242,0.364,0.544,0.363,0.896c-0.001,0.99-0.633,1.629-1.611,1.629c-0.494,0-0.786-0.105-1.021-0.189
			c-0.168-0.061-0.3-0.108-0.467-0.108c-0.224,0-0.566,0.223-0.874,0.567c-0.301,0.338-0.504,0.723-0.504,0.957l-0.004,4.309
			c0,0.756,0.507,1.078,0.932,1.216l-0.039,0.248l-4.159-0.003l-0.071-0.232C86.959,14.846,87.216,14.382,87.216,13.811z
			 M102.402,15.048c0.645,0.502,1.511,0.778,2.441,0.778h0.007c0.81,0,1.578-0.15,2.445-0.48c0.414,0.001,0.839,0.045,1.266,0.09
			c0.433,0.045,0.88,0.092,1.316,0.092c0.309,0,0.582-0.022,0.838-0.07l0.255-0.047l-0.193-0.173c-0.815-0.729-1.084-3.004-1.32-5.011
			c-0.152-1.293-0.297-2.515-0.569-3.311c-0.323-0.944-1.188-1.424-2.578-1.425c-2.348,0-3.688,0.855-4.347,2.774l-0.113,0.329
			l0.632-0.384c0.589-0.367,1.574-0.982,2.033-0.982c0.774,0.001,1.349,0.31,1.711,0.917c-1.38,0.477-3.663,1.521-4.568,3.458
			c-0.15,0.338-0.355,0.8-0.355,1.159C101.3,13.696,101.681,14.486,102.402,15.048z M106.388,9.239
			c0.18,0.617,0.269,1.524,0.355,2.402c0.095,0.965,0.184,1.879,0.39,2.376c-0.051,0.033-0.099,0.064-0.146,0.095
			c-0.282,0.187-0.469,0.31-0.845,0.31c-0.621-0.001-1.008-0.379-1.224-0.695c-0.27-0.396-0.43-0.936-0.43-1.445
			C104.491,11.049,105.198,9.922,106.388,9.239z M96.614,13.891l0.004-5.031c0.001-1.133-0.221-1.579-0.721-2.583
			c1.021,0.001,2.023-0.369,3.008-0.647l-0.007,8.411c0.186,0.521,0.575,0.929,0.965,1.32l-4.216-0.003
			C96.167,14.986,96.614,14.578,96.614,13.891z M38.719,2.983l-0.584-0.312l0.038-0.086c0.687-1.532,0.356-2.336,0.354-2.344
			l-0.061-0.141l2.454,0.001L38.719,2.983z M46.923,42.594c-0.005,5.623-4.886,7.618-9.063,7.618h-0.012
			c-1.561-0.001-3.174-0.163-4.93-0.495c-0.271-0.047-0.554-0.108-0.842-0.17c-0.677-0.145-1.375-0.295-2.011-0.295
			c-0.771,0-1.35,0.244-1.897,0.811c-0.27-1.052-1.065-2.036-1.837-2.989c-0.571-0.708-1.114-1.379-1.446-2.062l2.939,0.002
			c0.69,0.357,1.517,0.838,2.392,1.347c2.392,1.391,5.102,2.967,7.386,2.968c2.963,0,6.157-1.917,6.16-5.014
			c0.003-3.386-3.788-4.271-7.803-5.206c-4.646-1.083-9.449-2.203-9.445-7.143c0.003-4.189,3.788-6.45,7.348-6.45
			c1.123,0.001,2.126,0.253,3.097,0.496c0.886,0.222,1.722,0.432,2.569,0.432c1.152,0,1.517-0.271,1.902-0.558
			c0.218-0.162,0.443-0.33,0.842-0.463c0.432,0.685,0.823,1.396,1.203,2.086c0.422,0.768,0.856,1.559,1.35,2.313l-3.207-0.001
			c-0.472-0.001-1.225-0.497-2.097-1.071c-1.421-0.937-3.188-2.103-5.07-2.104c-2.044,0-4.157,1.231-4.159,3.292
			c-0.003,2.764,4.424,3.911,6.09,4.343l1.039,0.255c2.471,0.601,5.025,1.223,7.274,2.297C46.696,37.79,46.925,40.579,46.923,42.594z
			 M15.59,21.691c3.404,0,6.112-1.73,8.119-3.305v0.877c-2.482,4.2-5.561,5.862-10.962,5.862h-0.021
			c-3.632-0.003-6.694-1.191-8.854-3.437c-2.132-2.216-3.257-5.316-3.254-8.967C0.627,4.659,6.44,0.308,11.907,0.308
			c2.342,0.002,3.849,0.855,5.307,1.682c1.216,0.688,2.364,1.339,3.837,1.34c0.438,0,0.831-0.045,1.288-0.149l-0.003,3.839
			c0,0.945-1.877,4.189-2.673,4.984c-0.992,0.965-2.501,1.435-4.61,1.435c-1.53-0.001-2.899-0.368-3.864-1.034
			c-1.131-0.78-1.704-1.923-1.703-3.396c0.001-1.175,0.744-2.425,2.118-2.425c0.921,0.001,1.873,0.588,1.872,1.567
			c-0.001,1.189-1.149,1.289-2.226,1.289l-0.676-0.003l0.044,0.161c0.493,1.816,1.189,2.37,2.981,2.371
			c2.529,0,3.853-2.353,3.854-4.677c0.002-3.589-2.515-6.098-6.121-6.101c-1.53,0-3.078,0.785-4.736,2.401
			c-1.66,1.597-2.503,3.697-2.505,6.243c-0.003,3.114,1.177,6.066,3.32,8.315C9.586,20.432,12.492,21.689,15.59,21.691z M98.7,45.059
			l2.623,0.016l-0.145,0.21c-0.609,0.878-1.301,1.873-1.73,2.84c-0.138,0.325-0.267,0.652-0.396,0.979
			c-0.233,0.594-0.475,1.206-0.76,1.785l-0.191-0.049c-1.547-0.391-3.146-0.795-4.722-0.796l-12.281-0.01
			c0.489-0.734,1.058-1.715,1.059-2.486l0.018-21.63c0-0.923-0.522-2.126-1.075-2.979c0.877-0.116,1.888-0.171,3.158-0.171
			c0.652,0,1.308,0.013,1.954,0.026c0.507,0.011,1.008,0.021,1.498,0.024c-1.47,1.385-1.448,3.379-1.428,5.31l0.005,0.685
			L86.27,48.846l3.94,0.003l0.179-0.063C93.259,47.586,95.97,46.453,98.7,45.059z"/>
		<path className="outline" d="M5.824,41.847c-0.041,0.024-0.08,0.048-0.117,0.071l0.01-0.025L5.824,41.847z M5.552,36.156l-0.003,4.353
		c0,0.554-0.477,0.785-0.938,0.954c-0.386,0-0.754-0.754-0.891-1.047c-0.4-0.83-0.492-1.831-0.491-2.753
		c0.001-0.969,0.079-2.583,1.386-2.583c0,0,0.001,0,0.001,0C5.061,35.079,5.552,35.756,5.552,36.156z M5.297,36.156
		c0-0.11-0.076-0.322-0.244-0.528c-0.146-0.179-0.318-0.295-0.439-0.295c-0.781,0-1.13,0.718-1.132,2.329
		c-0.001,1.141,0.147,1.981,0.467,2.645c0.339,0.725,0.558,0.866,0.632,0.894c0.441-0.168,0.711-0.348,0.712-0.693L5.297,36.156z
		 M5.423,34.378l0.002-1.956l-0.042-0.078c-0.179-0.333-0.333-0.621-0.562-0.902l-0.291-0.357l0.457-0.057
		c0.764-0.094,1.631-0.327,2.508-0.674l0.348-0.138l0,0.375l-0.008,10.32c0,0.471,0.221,0.794,0.604,1.193l0.413,0.431l-0.597,0
		l-2.43-0.002l-0.15,0L5.6,42.401l-0.049-0.089c-0.554,0.339-0.832,0.481-1.774,0.481c-1.082-0.001-1.957-0.48-2.532-1.385
		c-0.464-0.731-0.719-1.729-0.718-2.81c0.002-2.232,1.241-4.604,3.535-4.604C4.642,33.994,5.009,34.158,5.423,34.378z M5.679,32.357
		l-0.002,2.446c-0.585-0.308-0.939-0.554-1.615-0.555c-0.001,0-0.002,0-0.003,0c-2.245,0-3.276,2.413-3.278,4.35
		c-0.001,1.877,0.812,3.938,2.995,3.94c0.003,0,0.005,0,0.008,0c1.005,0,1.153-0.154,1.921-0.62l-0.035,0.084l0.152,0.277l2.43,0.002
		c-0.384-0.4-0.675-0.786-0.675-1.37l0.008-10.32c-0.815,0.322-1.708,0.583-2.57,0.69C5.294,31.619,5.463,31.957,5.679,32.357z
		 M16.15,40.817l0.837-0.294l-0.554,0.693c-0.836,1.046-2.079,1.646-3.415,1.646c-2.406-0.002-4.437-2.036-4.435-4.441
		c0.002-2.09,1.655-4.419,4.023-4.419h0.003h0c2.074,0.002,2.843,1.802,3.461,3.249l0.021,0.048v0.052l-0.001,0.815l0,0.255l-0.255,0
		l-4.607-0.004c0.663,1.735,1.934,2.722,3.53,2.723C15.232,41.139,15.72,40.968,16.15,40.817z M16.235,41.057
		c-0.478,0.168-0.969,0.337-1.476,0.337c0,0-0.001,0-0.002,0c-2.014-0.002-3.306-1.418-3.888-3.233l4.967,0.004l0.001-0.815
		c-0.644-1.508-1.351-3.093-3.227-3.094c-0.001,0-0.002,0-0.003,0c-2.167,0-3.767,2.12-3.769,4.165
		c-0.002,2.276,1.903,4.185,4.181,4.186c0.001,0,0.002,0,0.003,0C14.266,42.607,15.449,42.04,16.235,41.057z M10.869,37.484
		c-0.062-0.077-0.109-0.17-0.124-0.262c0.032-0.138,0.125-0.262,0.125-0.415c0.001-0.938,0.093-1.599,1.198-1.599
		c0.001,0,0.002,0,0.002,0c0.416,0,0.692,0.216,0.862,0.585c0.092,0.169,0.49,1.184,0.49,1.292l0,0.4L10.869,37.484z M11.009,37.227
		c0,0.001,0.001,0.001,0.001,0.002l2.158,0.002l0.001-0.119c-0.026-0.141-0.382-1.052-0.469-1.213
		c-0.136-0.298-0.337-0.436-0.633-0.437c-0.806,0-0.942,0.336-0.943,1.344c0,0.145-0.049,0.262-0.089,0.357
		C11.027,37.185,11.017,37.206,11.009,37.227z M53.643,36.613c-0.001,1.449,0.866,3.213,2.526,3.215l0-0.255
		c-1.568-0.001-2.272-1.732-2.272-2.96c0.001-1.72,0.935-3.237,2.574-4.204c0.236,0.867,0.357,2.107,0.475,3.31
		c0.131,1.334,0.255,2.602,0.533,3.328c-0.053,0.034-0.104,0.068-0.153,0.1c-0.388,0.257-0.645,0.426-1.156,0.426l-0.002,0.255
		c0,0,0.001,0,0.002,0c0.737,0,1.026-0.315,1.631-0.683c-0.63-1.212-0.547-5.269-1.178-7.113
		C54.884,32.953,53.645,34.585,53.643,36.613z M77.006,40.771l0.433,0.435l-0.614,0l-5.003-0.004l-0.305,0l0.054-0.3
		c0.289-1.593,0.314-3.192,0.315-4.827c0.001-0.838-0.052-1.686-0.103-2.505c-0.051-0.818-0.104-1.664-0.103-2.499v-0.04l0.013-0.038
		c0.048-0.148,0.172-0.83,0.173-0.948c0-0.421-0.168-0.905-1.485-0.906c-0.716,0-1.667,0.965-1.668,1.693l-0.007,8.271
		c0,0.696,0.348,1.255,1.064,1.708l0.744,0.471l-0.88-0.001l-5.979-0.005l-1.111-0.001l0.204-0.099l-0.08,0.015
		c-0.366,0.068-0.759,0.101-1.2,0.101c-0.624,0-1.261-0.067-1.877-0.131c-0.579-0.061-1.177-0.123-1.744-0.127
		c-1.28,0.471-2.366,0.681-3.522,0.681c-1.335-0.001-2.58-0.399-3.506-1.12c-1.041-0.811-1.591-1.952-1.59-3.3
		c0-0.53,0.3-1.203,0.518-1.694l0.032-0.073c1.239-2.647,4.419-4.129,6.394-4.822c-0.502-0.797-1.262-1.186-2.314-1.186
		c-0.632,0-2.022,0.866-2.853,1.384c-0.178,0.111-0.337,0.21-0.468,0.288l-0.598,0.357l0.226-0.659
		c0.947-2.756,2.871-3.985,6.239-3.986h0.011h0c1.993,0.002,3.243,0.698,3.716,2.071c0.387,1.136,0.592,2.869,0.808,4.704
		c0.334,2.838,0.713,6.055,1.85,7.07l0.294,0.263l0.459-0.222c0.779-0.376,1.069-0.835,1.07-1.693l0.007-8.772
		c0.001-0.998-0.406-1.464-1.021-2.17l-0.259-0.3l-0.354-0.42h0.551c1.125,0,3.043-0.43,4.104-0.921l0.051-0.024h0.056l0.712,0.001
		l0.255,0l0,0.255l-0.001,1.23c0.925-0.487,2.566-1.245,3.828-1.245c1.471,0.001,3.599,1.022,3.598,2.865
		c-0.001,1.607-0.016,3.214-0.031,4.819c-0.015,1.603-0.029,3.202-0.031,4.795c0,0.641,0.332,0.971,0.793,1.427L77.006,40.771z
		 M62.621,40.94c-1.948-1.74-1.732-8.956-2.729-11.881c-0.526-1.527-2.027-1.897-3.475-1.899c-0.004,0-0.008,0-0.011,0
		c-2.945,0-4.996,0.895-5.999,3.814c0.792-0.473,2.608-1.709,3.451-1.709c0,0,0,0,0.001,0c1.212,0.001,2.134,0.501,2.686,1.583
		c-2.45,0.814-5.402,2.365-6.536,4.788c-0.21,0.474-0.526,1.159-0.527,1.658c-0.002,2.713,2.366,4.164,4.841,4.166
		c0.002,0,0.005,0,0.007,0c1.237,0,2.34-0.262,3.47-0.681c1.196,0.001,2.452,0.258,3.667,0.258
		C61.858,41.036,62.243,41.01,62.621,40.94z M76.825,40.951c-0.525-0.527-1.001-0.923-1-1.739c0.002-3.187,0.059-6.401,0.061-9.613
		c0.001-1.607-1.922-2.609-3.344-2.61c-0.001,0-0.001,0-0.002,0c-1.263,0-3.002,0.814-4.08,1.419l0.001-1.659l-0.712-0.001
		c-1.078,0.498-3.025,0.945-4.211,0.945c-0.001,0-0.003,0-0.004,0c0.763,0.896,1.341,1.397,1.34,2.635l-0.007,8.772
		c-0.001,0.948-0.342,1.501-1.213,1.922l5.979,0.005c-0.709-0.449-1.183-1.055-1.182-1.924l0.007-8.271
		c0.001-0.869,1.054-1.948,1.922-1.948c0,0,0.001,0,0.001,0c0.791,0.001,1.739,0.16,1.738,1.161c0,0.159-0.134,0.87-0.185,1.027
		c-0.001,1.66,0.208,3.319,0.206,5.005c-0.001,1.633-0.028,3.266-0.319,4.872L76.825,40.951z M112.403,40.852l0.434,0.435l-0.615,0
		l-5.979-0.005l-0.795-0.001l0.074-0.052l-0.289,0l-4.714-0.004l-0.255,0l0-0.255l0-0.325c-1.059,0.496-2.574,0.946-3.75,0.946
		c-1.506-0.001-3.781-1.145-3.78-2.866l0.007-8.85c0-0.377-0.301-0.809-0.592-1.225c-0.181-0.259-0.352-0.504-0.464-0.75
		l-0.162-0.361h0.397c0.742,0,1.495-0.194,2.224-0.381c0.732-0.188,1.488-0.383,2.238-0.383c0.051,0,0.102,0.001,0.153,0.003
		l0.246,0.009l0,0.246l-0.008,9.799c-0.001,0.845,0.757,1.879,1.643,1.88c0.736,0,1.85-0.507,1.851-1.271l0.004-5.473
		c0-0.572-0.381-1.073-0.751-1.558c-0.096-0.126-0.191-0.251-0.28-0.376l-0.278-0.401h0.491c1.448,0,3.099-0.484,4.524-0.958
		l0.336-0.112l-0.001,0.354l-0.036,10.397c0,0.723,0.232,0.876,0.795,1.246c0.095,0.063,0.197,0.129,0.305,0.204l0.404,0.279
		l0.314-0.224c0.781-0.556,1.265-1.063,1.265-1.872l0.006-7.138c0.001-1.549-0.291-2.134-0.998-3.548l-0.18-0.369h0.412
		c1.139,0,2.292-0.345,3.407-0.678c0.264-0.079,0.526-0.157,0.788-0.231l0.324-0.092l0,0.337l-0.01,11.887
		C111.353,39.766,111.839,40.288,112.403,40.852z M105.234,40.974c-0.763-0.527-1.211-0.659-1.21-1.66l0.036-10.398
		c-1.42,0.472-3.104,0.971-4.604,0.971c-0.002,0-0.004,0-0.006,0c0.446,0.632,1.078,1.265,1.077,2.081l-0.004,5.473
		c-0.001,0.921-1.211,1.525-2.105,1.525c0,0-0.001,0-0.002,0c-1.027-0.001-1.897-1.16-1.896-2.134l0.008-9.799
		c-0.048-0.002-0.096-0.003-0.144-0.003c-1.454,0-2.933,0.763-4.462,0.763c-0.001,0-0.002,0-0.004,0
		c0.289,0.632,1.08,1.37,1.079,2.081l-0.007,8.85c-0.001,1.554,2.158,2.61,3.526,2.611c0.001,0,0.002,0,0.003,0
		c1.211,0,2.923-0.525,4.001-1.103l0,0.738L105.234,40.974z M112.223,41.032c-0.553-0.554-1.106-1.133-1.367-1.871l0.01-11.932
		c-1.395,0.394-2.818,0.919-4.264,0.919c-0.002,0-0.003,0-0.005,0c0.711,1.422,1.026,2.055,1.024,3.662l-0.006,7.138
		c-0.001,0.974-0.634,1.553-1.372,2.079L112.223,41.032z M111.333,23.543c-0.002,1.858-1.247,2.096-2.337,2.096
		c-1.424-0.001-2.308-0.887-2.307-2.311c0.001-1.09,1.232-2.333,2.311-2.333h0C110.332,20.996,111.335,22.091,111.333,23.543z
		 M111.079,23.543c0.001-1.212-0.79-2.292-2.079-2.293c0,0,0,0-0.001,0c-0.95,0-2.054,1.131-2.055,2.079
		c-0.001,1.291,0.762,2.055,2.053,2.056c0.002,0,0.003,0,0.005,0C110.341,25.384,111.078,24.963,111.079,23.543z M126.811,36.961
		c-0.003,3.509-3.06,4.754-5.684,4.754c-0.387,0-0.791-0.068-1.181-0.134c-0.38-0.064-0.773-0.13-1.149-0.13
		c-0.929,0-1.864,0.079-3.031,0.257l-0.168,0.026l-0.088-0.146c-0.341-0.565-0.716-1.123-1.079-1.662
		c-0.361-0.536-0.734-1.091-1.077-1.658l-0.234-0.387l0.452,0l2.582,0.002l0.069,0l0.059,0.035c0.238,0.139,0.506,0.302,0.796,0.479
		c1.452,0.885,3.442,2.098,4.737,2.099c0.905,0,2.117-0.915,2.118-1.851c0.001-1.539-1.92-2.167-4.145-2.893
		c-2.698-0.881-5.755-1.88-5.753-4.937c0.003-3.483,2.907-3.886,5.104-3.886c0.496,0,0.995,0.035,1.478,0.068
		c0.475,0.032,0.966,0.066,1.448,0.067c0.532,0,0.842-0.077,1.335-0.249l0.117-0.041l0.104,0.067
		c0.584,0.374,0.957,0.955,1.317,1.516c0.268,0.418,0.521,0.813,0.852,1.122l0.471,0.441l-0.646,0l-2.37-0.002
		c-0.507,0-1.052-0.35-1.682-0.756c-0.815-0.524-1.739-1.117-2.797-1.118c-0.313,0-1.877,0.066-1.878,1.377
		c-0.001,1.105,2.416,1.796,3.211,2.022l0.448,0.128c1.555,0.443,3.163,0.902,4.578,1.635
		C126.245,33.801,126.813,35.065,126.811,36.961z M126.557,36.961c0.001-1.317-0.261-2.845-1.552-3.53
		c-1.526-0.792-3.317-1.267-4.976-1.743c-0.922-0.263-3.396-0.976-3.395-2.267c0.001-1.184,1.106-1.631,2.132-1.631
		c0.001,0,0.002,0,0.003,0c1.949,0.002,3.449,1.873,4.476,1.874l2.37,0.002c-0.816-0.764-1.184-2.003-2.132-2.61
		c-0.527,0.184-0.867,0.263-1.418,0.263c-0.001,0-0.002,0-0.004,0c-0.976-0.001-1.949-0.134-2.923-0.134c-0.005,0-0.009,0-0.013,0
		c-2.576,0-4.834,0.607-4.836,3.631c-0.004,5.294,9.901,4.063,9.898,7.83c-0.001,1.106-1.345,2.105-2.373,2.105c0,0-0.001,0-0.001,0
		c-1.632-0.001-4.213-1.768-5.66-2.612l-2.582-0.002c0.686,1.133,1.474,2.188,2.157,3.32c1.023-0.156,2.021-0.26,3.07-0.26
		c0.004,0,0.009,0,0.013,0c0.763,0.001,1.58,0.264,2.318,0.265c0.002,0,0.004,0,0.007,0
		C123.949,41.461,126.554,40.093,126.557,36.961z M48.403,43.102c0.013,0.304,0.021,0.603,0.02,0.883
		c0,0.076-0.001,0.152-0.003,0.227c-0.002,0.071-0.017,0.134-0.02,0.204c-0.015,0.298-0.043,0.588-0.086,0.867
		c-0.009,0.06-0.008,0.127-0.018,0.186c-0.009,0.048-0.026,0.09-0.036,0.138c-0.057,0.288-0.129,0.565-0.215,0.832
		c-0.014,0.044-0.021,0.093-0.035,0.137c-0.003,0.01-0.009,0.018-0.012,0.028c-0.124,0.353-0.278,0.682-0.449,0.997
		c-0.018,0.034-0.029,0.073-0.048,0.106c-0.026,0.045-0.061,0.083-0.087,0.127c-0.151,0.252-0.315,0.493-0.495,0.72
		c-0.035,0.044-0.066,0.092-0.102,0.135c-0.216,0.259-0.453,0.499-0.704,0.724c-0.063,0.057-0.129,0.109-0.194,0.163
		c-0.214,0.179-0.437,0.346-0.671,0.502c-0.064,0.043-0.125,0.088-0.19,0.129c-0.29,0.182-0.593,0.347-0.908,0.495
		c-0.068,0.032-0.139,0.059-0.208,0.09c-0.266,0.117-0.54,0.223-0.821,0.318c-0.087,0.029-0.172,0.06-0.26,0.087
		c-0.344,0.106-0.695,0.199-1.055,0.273c-0.052,0.011-0.107,0.017-0.16,0.027c-0.318,0.061-0.642,0.107-0.969,0.143
		c-0.1,0.011-0.2,0.021-0.301,0.03c-0.379,0.032-0.761,0.054-1.148,0.054c-1.658-0.001-3.316-0.188-4.959-0.504
		c-0.886-0.158-1.929-0.459-2.815-0.46c-0.843-0.001-1.415,0.299-1.973,0.956l-1.385-1.387c0.001-0.001,0.002-0.002,0.003-0.004
		c-0.34-1.937-2.643-3.66-3.408-5.443l3.137,0.002c2.798,1.385,6.703,4.312,9.778,4.314c0.001,0,0.003,0,0.005,0
		c2.768,0,6.025-1.811,6.027-4.887c0-0.657-0.149-1.216-0.418-1.699c-1.087-0.71-2.57-1.181-4.202-1.591
		c-3.444-0.866-7.508-1.492-9.684-3.672l-1.385-1.387c0,0,0.001,0,0.001,0.001l-0.001-0.001c0.006,0.005,0.013,0.01,0.018,0.016
		c-0.977-0.974-1.579-2.258-1.577-4.015c0.003-4.119,3.632-6.577,7.474-6.577c0.002,0,0.004,0,0.007,0
		c2.153,0.002,3.907,0.926,5.659,0.928c0.002,0,0.004,0,0.006,0c1.808,0,1.566-0.677,2.794-1.044l1.385,1.387
		c0.985,1.544,1.712,3.175,2.725,4.676l-3.43-0.003c-1.301-0.001-4.127-3.162-7.172-3.165c-0.208,0-0.421,0.018-0.634,0.046
		c-0.063,0.008-0.127,0.021-0.19,0.031c-0.155,0.026-0.31,0.059-0.463,0.1c-0.066,0.017-0.132,0.034-0.197,0.054
		c-0.182,0.056-0.36,0.122-0.533,0.198c-0.026,0.012-0.055,0.02-0.081,0.032c-0.196,0.091-0.382,0.198-0.558,0.315
		c-0.049,0.033-0.094,0.07-0.142,0.104c-0.126,0.092-0.246,0.192-0.357,0.298c-0.048,0.046-0.095,0.092-0.14,0.141
		c-0.113,0.123-0.214,0.254-0.304,0.393c-0.023,0.036-0.051,0.068-0.073,0.104c-0.103,0.176-0.184,0.367-0.245,0.568
		c-0.01,0.034-0.018,0.07-0.027,0.105c-0.054,0.21-0.09,0.43-0.09,0.666c0,0.152,0.022,0.295,0.049,0.437
		c0.013,0.071,0.031,0.14,0.051,0.208c0.023,0.08,0.051,0.157,0.082,0.234c0.04,0.1,0.092,0.194,0.145,0.288
		c1.35,0.875,3.183,1.381,4.287,1.667c2.738,0.679,5.753,1.327,8.336,2.56c0.403,0.193,0.719,0.473,0.994,0.788
		c-0.052-0.06-0.088-0.134-0.145-0.19l1.385,1.387c0.199,0.199,0.363,0.43,0.511,0.672c0.042,0.069,0.08,0.141,0.119,0.213
		c0.106,0.197,0.199,0.403,0.279,0.618c0.016,0.043,0.041,0.08,0.055,0.123c0.009,0.025,0.013,0.053,0.022,0.078
		c0.053,0.16,0.096,0.324,0.138,0.49c0.02,0.081,0.045,0.159,0.063,0.241c0.05,0.232,0.091,0.467,0.124,0.703
		c0.002,0.016,0.006,0.032,0.009,0.049c0,0.001,0,0.002,0,0.003c0.044,0.327,0.069,0.653,0.087,0.974
		C48.396,42.947,48.4,43.023,48.403,43.102z M46.779,39.723c0.003,0.015,0.007,0.03,0.01,0.045c-0.001-0.005-0.002-0.011-0.003-0.017
		C46.785,39.741,46.781,39.732,46.779,39.723z M46.543,38.91c0.016,0.044,0.034,0.088,0.049,0.133
		c-0.003-0.008-0.004-0.016-0.007-0.023C46.573,38.982,46.557,38.947,46.543,38.91z M46.091,37.962
		c0.052,0.083,0.099,0.168,0.146,0.255c-0.003-0.005-0.005-0.011-0.008-0.017c-0.039-0.072-0.077-0.144-0.119-0.213
		C46.104,37.978,46.097,37.971,46.091,37.962z M31.81,27.544c-0.063,0.042-0.121,0.089-0.181,0.134
		c0.007-0.005,0.015-0.011,0.022-0.016c0.048-0.035,0.093-0.072,0.143-0.105C31.799,27.552,31.805,27.548,31.81,27.544z
		 M33.089,26.977c-0.062,0.017-0.123,0.031-0.184,0.051c0.02-0.006,0.041-0.011,0.061-0.017
		C33.007,26.998,33.048,26.988,33.089,26.977z M33.892,26.813c-0.133,0.015-0.264,0.039-0.397,0.065
		c0.043-0.008,0.087-0.014,0.13-0.021c0.064-0.011,0.126-0.023,0.19-0.031C33.841,26.823,33.867,26.816,33.892,26.813z
		 M40.112,29.283c-1.47-0.93-3.51-2.509-5.661-2.511c-0.001,0-0.002,0-0.004,0c-0.136,0-0.276,0.018-0.415,0.03
		c0.14-0.012,0.28-0.023,0.417-0.023C36.6,26.782,38.64,28.357,40.112,29.283z M46.796,42.594c0.002-1.98-0.22-4.721-2.156-5.647
		c-2.237-1.068-4.786-1.688-7.251-2.288l-1.037-0.254c-0.876-0.227-2.499-0.651-3.864-1.4c0.056,0.065,0.101,0.135,0.163,0.197
		l-0.241-0.241c-1.239-0.697-2.247-1.668-2.246-3.022c0.001-2.141,2.179-3.42,4.283-3.42c1.924,0.002,3.709,1.178,5.144,2.124
		c0.855,0.563,1.593,1.05,2.027,1.05l2.974,0.002c-0.444-0.699-0.842-1.423-1.228-2.126c-0.363-0.659-0.736-1.339-1.145-1.993
		c-0.32,0.119-0.511,0.262-0.712,0.411c-0.403,0.3-0.784,0.583-1.973,0.583c-0.868-0.001-1.711-0.212-2.604-0.436
		c-0.964-0.241-1.96-0.491-3.061-0.492c-3.504,0-7.223,2.216-7.227,6.323c-0.004,4.839,4.75,5.947,9.347,7.019
		c3.408,0.795,6.648,1.558,7.607,3.838l0.408,0.409c-0.11-0.11-0.242-0.207-0.367-0.307c0.159,0.414,0.253,0.87,0.252,1.39
		c-0.002,3.176-3.263,5.141-6.282,5.141c-2.323-0.002-5.049-1.587-7.454-2.985c-0.853-0.496-1.659-0.965-2.389-1.33l-2.67-0.002
		c0.33,0.608,0.821,1.216,1.336,1.853c0.73,0.903,1.481,1.833,1.802,2.832c0.527-0.481,1.107-0.7,1.831-0.7
		c0.651,0.001,1.356,0.152,2.038,0.298c0.287,0.062,0.569,0.122,0.837,0.169c1.754,0.331,3.359,0.492,4.91,0.493
		C44.451,50.085,46.793,46.049,46.796,42.594z M31.207,28.032c0.026-0.027,0.061-0.047,0.088-0.074
		c0.001-0.001,0.003-0.003,0.004-0.004C31.27,27.982,31.235,28.003,31.207,28.032z M30.783,31.186
		c-0.016-0.026-0.032-0.052-0.047-0.079c0.029,0.053,0.054,0.107,0.087,0.158C30.807,31.24,30.799,31.212,30.783,31.186z
		 M30.597,30.815c-0.005-0.012-0.013-0.023-0.018-0.036c0.014,0.036,0.028,0.073,0.043,0.109
		C30.612,30.865,30.606,30.839,30.597,30.815z M30.464,30.373c-0.001-0.008-0.004-0.015-0.005-0.023
		c0.004,0.025,0.006,0.05,0.011,0.074C30.467,30.408,30.468,30.39,30.464,30.373z M101.565,44.948l1.379,1.382v0
		c-0.002,0.004-0.005,0.008-0.008,0.011c-0.198,0.286-0.415,0.596-0.629,0.907c-0.077,0.111-0.153,0.225-0.229,0.339
		c-0.252,0.375-0.501,0.759-0.721,1.147c-0.155,0.275-0.298,0.551-0.417,0.825c-0.401,0.943-0.745,1.929-1.204,2.858
		c-1.6-0.401-3.315-0.875-4.973-0.876l-12.521-0.01l-1.369-1.371h-0.014c0.002-0.003,0.004-0.006,0.006-0.009l-0.008-0.008h0.013
		c0.462-0.677,1.159-1.773,1.16-2.597l0.017-21.63c0.001-0.984-0.614-2.277-1.167-3.077c1.076-0.159,2.221-0.201,3.373-0.201
		c1.285,0,2.578,0.052,3.794,0.053l1.385,1.387c-0.223,0.178-0.412,0.372-0.579,0.575c-0.056,0.068-0.098,0.143-0.149,0.214
		c-0.099,0.138-0.195,0.277-0.274,0.424c-0.063,0.118-0.111,0.241-0.163,0.364c-0.05,0.119-0.104,0.237-0.144,0.36
		c-0.058,0.178-0.1,0.362-0.139,0.547c-0.02,0.093-0.047,0.182-0.063,0.276c-0.017,0.098-0.026,0.2-0.039,0.299
		c-0.021,0.161-0.042,0.322-0.055,0.486c-0.009,0.115-0.013,0.23-0.018,0.346c-0.007,0.143-0.013,0.285-0.016,0.429
		c-0.011,0.601,0.003,1.208,0.002,1.797l-1.383-1.386c0,0,0,0,0,0l1.383,1.386l-0.02,18.51l-1.379-0.001v0.015l3.813,0.003
		c2.862-1.197,5.664-2.363,8.431-3.776l2.914,0.002c0.001-0.002,0.002-0.003,0.003-0.005L101.565,44.948L101.565,44.948
		C101.565,44.948,101.565,44.948,101.565,44.948z M101.079,45.202L98.703,45.2c-2.709,1.38-5.397,2.504-8.243,3.693l-0.152,0.063
		l-0.047,0.02H90.21l-3.813-0.003l-0.255,0l0-0.255l0.016-19.908c0-0.225-0.002-0.452-0.005-0.681
		c-0.019-1.777-0.041-3.768,1.255-5.188c-0.392-0.005-0.791-0.013-1.193-0.021c-0.649-0.014-1.307-0.027-1.963-0.027
		c-1.169,0-2.116,0.047-2.941,0.145c0.522,0.855,0.99,1.991,0.989,2.879l-0.017,21.63c-0.001,0.736-0.487,1.637-0.951,2.359
		l12.046,0.01c1.593,0.001,3.202,0.408,4.758,0.802l0.086,0.022c0.264-0.549,0.493-1.128,0.714-1.69
		c0.128-0.325,0.256-0.65,0.392-0.972c0.438-0.985,1.134-1.987,1.748-2.872L101.079,45.202z M15.359,45.547l0.509-0.006
		c0.075,1.704-0.302,3.001-1.119,3.855c-0.66,0.689-1.583,1.054-2.676,1.054c-1.223-0.001-2.29-0.324-4.572-1.384l-0.54-0.268
		c-1.191-0.593-2.132-1.062-3.04-1.063c-0.978,0-3.348,0.681-3.406,2.367l-0.509,0.001c-0.064-1.612,0.347-2.927,1.188-3.802
		c0.695-0.723,1.638-1.105,2.727-1.105h0.003h0c1.354,0.001,2.395,0.431,4.443,1.412l0.166,0.078
		c1.468,0.692,2.529,1.192,3.572,1.193C13.066,47.879,15.329,47.086,15.359,45.547z M15.614,45.552
		c-0.033,1.691-2.397,2.581-3.507,2.581c0,0-0.001,0-0.002,0c-1.139-0.001-2.275-0.556-3.844-1.295
		c-2.06-0.986-3.044-1.387-4.335-1.388c-0.001,0-0.002,0-0.003,0c-2.122,0-3.783,1.568-3.661,4.642
		c0.064-1.845,2.551-2.612,3.661-2.612c0.001,0,0.001,0,0.002,0c1.107,0.001,2.245,0.648,3.69,1.356
		c2.123,0.986,3.229,1.357,4.46,1.358c0.001,0,0.002,0,0.003,0C13.982,50.195,15.765,48.995,15.614,45.552z M24.804,20.228
		c-2.649,4.515-5.967,5.984-11.112,5.98c-3.685-0.003-6.692-1.22-8.8-3.332l-0.926-0.927c-2.228-2.209-3.475-5.402-3.472-9.226
		C0.498,6.142,4.992,0.181,11.907,0.181c0.003,0,0.006,0,0.01,0c4.369,0.003,5.996,3.019,9.134,3.022c0.001,0,0.003,0,0.004,0
		c0.492,0,0.919-0.061,1.411-0.183l0.969,0.971l-0.004,3.988c-0.001,0.986-1.906,4.272-2.707,5.072
		c-1.231,1.2-3.046,1.484-4.704,1.483c-1.736-0.001-3.441-0.473-4.523-1.557l-0.753-0.754c-0.849-0.731-1.387-1.783-1.386-3.214
		c0.001-1.322,0.861-2.551,2.245-2.551c0.001,0,0.001,0,0.002,0c0.984,0.001,1.998,0.648,1.998,1.694
		c0,0.655-0.314,1.008-0.761,1.198c0.452-0.189,0.77-0.543,0.771-1.204c0-0.436-0.177-0.803-0.455-1.082l0.969,0.971
		c0.278,0.279,0.455,0.645,0.455,1.082c-0.001,1.323-1.272,1.417-2.346,1.416c-0.179,0-0.353-0.003-0.514-0.003
		c0.116,0.428,0.243,0.779,0.393,1.073c0.38,0.163,0.854,0.24,1.485,0.241c0.001,0,0.002,0,0.003,0c2.49,0,3.722-2.306,3.723-4.55
		c0.001-1.503-0.451-2.804-1.237-3.806c-1.004-0.766-2.296-1.203-3.772-1.205C10.487,2.279,8.9,3.449,7.669,4.649
		c-1.731,1.657-2.477,3.814-2.479,6.144c-0.003,3.072,1.188,5.924,3.139,8.052c1.954,1.685,4.473,2.717,7.262,2.719
		c0.002,0,0.006,0,0.008,0c3.196,0,5.808-1.504,8.237-3.439v-0.012l0.969,0.971L24.804,20.228z M8.553,2.197
		C7.866,2.62,7.237,3.145,6.685,3.683C4.96,5.342,4.22,7.495,4.218,9.835c-0.002,2.414,0.733,4.693,1.994,6.592
		C4.95,14.525,4.217,12.239,4.22,9.822C4.223,7.492,4.968,5.335,6.7,3.678C7.248,3.143,7.872,2.62,8.553,2.197z M6.241,16.473
		c0.087,0.13,0.188,0.251,0.28,0.377C6.429,16.724,6.328,16.604,6.241,16.473z M23.582,18.648c-2.002,1.541-4.664,3.171-7.984,3.171
		C9.174,21.814,3.958,16.438,3.964,9.835c0.002-2.582,0.858-4.713,2.545-6.335c1.681-1.639,3.258-2.436,4.82-2.436
		c1.772,0.001,3.368,0.618,4.495,1.735c1.134,1.124,1.758,2.719,1.756,4.493c-0.002,2.388-1.369,4.805-3.978,4.805
		c-0.504,0-0.92-0.047-1.276-0.146c0.061,0.084,0.123,0.166,0.192,0.236l-0.259-0.26c-0.918-0.28-1.4-0.951-1.765-2.296l-0.088-0.322
		l0.333,0l0.237,0.001l0.274,0.001c1.293,0,2.095-0.195,2.096-1.163c0.001-0.9-0.886-1.438-1.743-1.439
		c-1.294,0-1.992,1.183-1.992,2.297c-0.001,1.429,0.553,2.536,1.648,3.291c0.944,0.651,2.288,1.01,3.784,1.011
		c2.084,0,3.562-0.458,4.531-1.4c0.761-0.76,2.633-3.992,2.634-4.892l0.003-3.68c-0.402,0.083-0.762,0.119-1.156,0.119
		c-1.511-0.001-2.674-0.66-3.905-1.357c-1.443-0.818-2.936-1.663-5.234-1.665h-0.009c-5.405,0-11.153,4.307-11.16,12.288
		c-0.006,7.451,4.696,12.27,11.979,12.275c5.345,0,8.389-1.629,10.855-5.804L23.582,18.648z M10.755,9.563c-0.005,0-0.01,0-0.014,0
		c0.205,0.752,0.443,1.273,0.795,1.633C11.186,10.838,10.957,10.309,10.755,9.563z M29.124,9.474
		c-0.075-0.093-0.131-0.204-0.149-0.315c0.036-0.168,0.15-0.316,0.15-0.502c0.001-1.131,0.113-1.929,1.446-1.929
		c0.001,0,0.002,0,0.002,0c0.501,0,0.837,0.26,1.041,0.706c0.111,0.204,0.593,1.43,0.593,1.56l0,0.483L29.124,9.474z M29.237,9.163
		c0.006,0.018,0.014,0.037,0.024,0.056l2.691,0.002l0.001-0.205c-0.028-0.165-0.464-1.278-0.571-1.477
		c-0.174-0.38-0.431-0.557-0.812-0.557c-1.077,0-1.19,0.51-1.191,1.674c0,0.163-0.056,0.298-0.102,0.406
		C29.264,9.096,29.249,9.129,29.237,9.163z M27.983,14.341c-1.011-1.013-1.567-2.34-1.566-3.737c0.002-2.499,1.976-5.283,4.805-5.283
		h0.003h0c2.466,0.002,3.388,2.158,4.129,3.891l0.021,0.048v0.052l-0.001,0.984l0,0.255l-0.255,0l-5.639-0.005
		c0.801,2.16,2.366,3.391,4.338,3.393c0.586,0,1.176-0.208,1.696-0.392l0.284,0.399c-0.997,1.251-2.483,1.968-4.081,1.968
		C30.321,15.913,28.994,15.354,27.983,14.341z M26.672,10.605c-0.002,2.748,2.298,5.052,5.046,5.055c0.001,0,0.003,0,0.004,0
		c1.502,0,2.932-0.686,3.878-1.872c-0.575,0.203-1.169,0.407-1.781,0.407c-0.001,0-0.001,0-0.002,0
		c-2.433-0.002-3.99-1.711-4.694-3.902l5.996,0.005l0.001-0.984c-0.779-1.821-1.63-3.734-3.896-3.736c-0.001,0-0.002,0-0.003,0
		C28.604,5.576,26.674,8.136,26.672,10.605z M36.682,6.377l-0.171-0.364h0.404c0.91,0,2.099-0.51,2.84-0.947l0.384-0.226l0,0.446
		l-0.001,1.189c0.043-0.047,0.084-0.093,0.125-0.138c0.583-0.645,1.043-1.155,2.277-1.155c0.999,0.001,2.073,0.494,2.072,1.575
		c-0.001,1.051-0.7,1.757-1.742,1.757c-0.514,0-0.818-0.11-1.062-0.198c-0.162-0.058-0.279-0.1-0.423-0.101
		c-0.408,0-1.25,0.94-1.25,1.398l-0.003,4.308c0,0.545,0.283,0.913,0.843,1.095l-0.079,0.497l-4.159-0.003l-0.143-0.465
		c0.468-0.318,0.706-0.747,0.706-1.274l0.005-5.813c0-0.346-0.275-0.89-0.477-1.288C36.775,6.565,36.725,6.467,36.682,6.377z
		 M36.912,6.268c0.222,0.465,0.648,1.189,0.647,1.69l-0.005,5.813c0,0.63-0.298,1.131-0.818,1.485l4.159,0.003
		c-0.63-0.205-1.02-0.652-1.019-1.338l0.003-4.308c0-0.576,0.928-1.652,1.505-1.652h0c0.445,0,0.669,0.298,1.485,0.298
		c0,0,0.001,0,0.002,0c0.927,0,1.484-0.612,1.485-1.502c0.001-0.966-1.02-1.32-1.818-1.32c-0.001,0-0.002,0-0.003,0
		c-1.446,0-1.69,0.742-2.654,1.669l0.001-1.82c-0.818,0.482-2.024,0.982-2.969,0.982C36.914,6.268,36.913,6.268,36.912,6.268z
		 M50.265,16.066c-0.469-0.772-0.797-1.687-1.115-2.572c-0.081-0.226-0.161-0.449-0.241-0.662l-0.267-0.697
		c-0.745-1.954-1.87-4.907-3.049-6.088l-0.434-0.435l0.615,0l4.384,0.004l0.101,0.488L50.04,6.196
		c-0.122,0.051-0.249,0.103-0.27,0.123c-0.015,0.034-0.013,0.177-0.012,0.315l0.001,0.201c-0.001,0.616,1.526,4.259,2.164,5.262
		c0.739-1.186,1.854-4.621,1.855-5.584c0-0.585-0.389-1.739-0.771-2.287l-0.28-0.401l0.489,0l3.733,0.003l0.521,0l-0.32,0.411
		c-1.397,1.794-3.157,5.935-3.935,8.114c-0.022,0.072-0.078,0.228-0.146,0.419c-0.108,0.302-0.314,0.881-0.381,1.134
		c0.376,0.406,0.696,0.82,0.987,1.209l0.298,0.407h-0.508c-1.098,0-1.89,0.237-2.887,0.646l-0.201,0.083L50.265,16.066z
		 M50.482,15.934c1.037-0.426,1.855-0.666,2.984-0.666c0.002,0,0.004,0,0.006,0c-0.333-0.446-0.668-0.874-1.058-1.282
		c0.021-0.242,0.484-1.467,0.559-1.708c0.782-2.19,2.547-6.358,3.977-8.195L53.217,4.08c0.389,0.558,0.817,1.765,0.817,2.433
		c-0.001,1.115-1.357,5.152-2.1,6.005c-0.521-0.446-2.429-4.959-2.429-5.684c0-0.779-0.074-0.65,0.652-0.964l-4.384-0.004
		c1.373,1.375,2.652,5.015,3.374,6.873C49.536,13.78,49.907,14.987,50.482,15.934z M57.108,10.629
		c0.002-2.499,1.976-5.283,4.804-5.283h0.003h0c2.466,0.002,3.388,2.158,4.129,3.891l0.021,0.048v0.052l-0.001,0.984l0,0.255
		l-0.255,0l-5.639-0.005c0.801,2.16,2.366,3.391,4.338,3.393c0.586,0,1.176-0.208,1.696-0.392l0.284,0.399
		c-1,1.251-2.485,1.968-4.081,1.968c-1.397-0.001-2.724-0.56-3.735-1.573C57.663,13.353,57.107,12.026,57.108,10.629z M57.363,10.629
		c-0.002,2.748,2.298,5.052,5.046,5.055c0.001,0,0.003,0,0.004,0c1.502,0,2.93-0.686,3.878-1.872
		c-0.575,0.203-1.169,0.407-1.781,0.407c-0.001,0-0.001,0-0.002,0c-2.431-0.002-3.99-1.711-4.694-3.902l5.996,0.005l0.001-0.984
		c-0.779-1.821-1.63-3.734-3.896-3.736c-0.001,0-0.002,0-0.003,0C59.295,5.601,57.365,8.161,57.363,10.629z M59.815,9.498
		c-0.075-0.093-0.131-0.204-0.149-0.315c0.039-0.168,0.15-0.316,0.15-0.502c0.001-1.131,0.113-1.929,1.446-1.929
		c0.001,0,0.002,0,0.003,0c0.501,0,0.837,0.26,1.041,0.706c0.111,0.204,0.593,1.43,0.593,1.56l0,0.483L59.815,9.498z M59.929,9.189
		c0.006,0.018,0.014,0.036,0.023,0.055l2.691,0.002l0.001-0.205c-0.028-0.165-0.464-1.278-0.571-1.477
		c-0.174-0.38-0.431-0.557-0.812-0.557c-1.077,0-1.19,0.51-1.191,1.674c0,0.165-0.057,0.301-0.102,0.41
		C59.954,9.123,59.94,9.156,59.929,9.189z M67.066,10.488c0.002-3.13,1.946-5.153,4.95-5.153h0.005h0
		c0.772,0.001,1.554,0.188,2.144,0.514c0.78,0.431,1.191,1.074,1.191,1.86c-0.001,1.013-1.063,1.719-2.018,1.719
		c-0.439,0-0.908-0.215-1.288-0.589c-0.382-0.376-0.601-0.843-0.6-1.281c0-0.12,0.041-0.329,0.317-0.878l-0.511,0
		c-0.937,0.405-1.413,1.243-1.414,2.492c-0.001,1.063,0.517,2.272,1.384,3.233c0.898,0.995,2.017,1.565,3.075,1.566
		c0.365,0,0.464-0.052,0.663-0.156c0.099-0.052,0.213-0.112,0.373-0.177l0.305,0.381c-0.818,1.177-2.263,1.908-3.776,1.908
		c-0.67,0-1.276-0.259-1.893-0.547C68.124,14.483,67.064,12.7,67.066,10.488z M67.321,10.489c-0.002,1.987,0.925,3.77,2.763,4.663
		c0.555,0.26,1.149,0.521,1.782,0.522c0.001,0,0.002,0,0.003,0c1.391,0,2.765-0.65,3.563-1.799c-0.5,0.203-0.519,0.352-1.131,0.352
		c-0.001,0-0.002,0-0.003,0c-2.413-0.002-4.713-2.714-4.711-5.054c0.001-1.225,0.446-2.265,1.617-2.746l0.984,0.001
		c-0.111,0.204-0.484,0.891-0.484,1.132c-0.001,0.779,0.854,1.615,1.634,1.616c0,0,0.001,0,0.001,0c0.78,0,1.761-0.574,1.762-1.465
		c0.001-1.541-1.799-2.119-3.08-2.12c-0.002,0-0.003,0-0.005,0C69.067,5.59,67.323,7.593,67.321,10.489z M81.506,15.954
		c-1.397-0.001-2.724-0.56-3.735-1.573c-1.011-1.013-1.567-2.34-1.566-3.737c0.002-2.499,1.976-5.283,4.805-5.283h0.003h0
		c2.466,0.002,3.388,2.158,4.129,3.891L85.164,9.3v0.052l-0.001,0.984l0,0.255l-0.255,0l-5.639-0.005
		c0.801,2.16,2.366,3.391,4.338,3.393c0.586,0,1.176-0.208,1.696-0.392l0.284,0.399C84.59,15.236,83.104,15.954,81.506,15.954z
		 M81.507,15.699c0.001,0,0.003,0,0.004,0c1.502,0,2.932-0.686,3.878-1.872c-0.575,0.203-1.169,0.407-1.781,0.407
		c-0.001,0-0.001,0-0.002,0c-2.433-0.002-3.99-1.711-4.694-3.902l5.996,0.005l0.001-0.984c-0.779-1.821-1.63-3.734-3.896-3.736
		c-0.001,0-0.002,0-0.003,0c-2.617,0-4.548,2.56-4.55,5.029C76.458,13.392,78.758,15.697,81.507,15.699z M78.913,9.514
		c-0.075-0.093-0.131-0.204-0.149-0.315c0.036-0.168,0.15-0.316,0.15-0.502c0.001-1.131,0.113-1.929,1.446-1.929
		c0.001,0,0.002,0,0.003,0c0.501,0,0.837,0.26,1.041,0.706c0.111,0.204,0.593,1.43,0.593,1.56l0,0.483L78.913,9.514z M79.026,9.203
		c0.006,0.018,0.014,0.037,0.023,0.056l2.692,0.002l0.001-0.205c-0.028-0.165-0.464-1.278-0.571-1.477
		c-0.174-0.38-0.431-0.557-0.812-0.557c-1.077,0-1.19,0.51-1.191,1.674c0,0.163-0.056,0.298-0.102,0.406
		C79.052,9.136,79.037,9.169,79.026,9.203z M86.471,6.417l-0.172-0.364h0.404c0.91,0,2.099-0.51,2.841-0.947l0.384-0.226l0,0.446
		l-0.001,1.189c0.043-0.047,0.085-0.093,0.125-0.138c0.583-0.645,1.043-1.155,2.277-1.155c0.999,0.001,2.073,0.494,2.072,1.575
		c-0.001,1.051-0.7,1.757-1.742,1.757c-0.514,0-0.818-0.11-1.062-0.198c-0.162-0.058-0.279-0.1-0.423-0.101
		c-0.408,0-1.249,0.94-1.25,1.398l-0.003,4.308c0,0.545,0.283,0.913,0.843,1.095l-0.079,0.497l-4.159-0.003l-0.143-0.465
		c0.468-0.318,0.706-0.747,0.706-1.275l0.005-5.812c0-0.346-0.275-0.89-0.477-1.287C86.564,6.605,86.514,6.506,86.471,6.417z
		 M86.701,6.307c0.222,0.465,0.648,1.189,0.647,1.69l-0.005,5.812c0,0.63-0.298,1.131-0.818,1.485l4.159,0.003
		c-0.63-0.205-1.02-0.652-1.019-1.338l0.003-4.308c0-0.576,0.928-1.652,1.504-1.652c0,0,0,0,0,0c0.444,0,0.669,0.298,1.485,0.298
		c0,0,0.001,0,0.002,0c0.927,0,1.484-0.612,1.485-1.502c0.001-0.966-1.02-1.32-1.818-1.32c-0.001,0-0.002,0-0.004,0
		c-1.446,0-1.689,0.742-2.653,1.669l0.001-1.82c-0.819,0.482-2.024,0.982-2.97,0.982C86.702,6.307,86.702,6.307,86.701,6.307z
		 M101.173,12.762c0-0.386,0.213-0.863,0.368-1.211l0.029-0.066c0.866-1.85,3.051-2.901,4.46-3.407
		c-0.34-0.485-0.839-0.721-1.516-0.722c-0.422,0-1.424,0.625-1.963,0.96c-0.129,0.08-0.244,0.152-0.338,0.208l-0.597,0.356
		l0.226-0.657c0.679-1.978,2.057-2.861,4.469-2.861h0.008h0c1.442,0.001,2.346,0.51,2.689,1.511c0.277,0.81,0.422,2.038,0.576,3.339
		c0.234,1.984,0.5,4.233,1.279,4.929l0.387,0.346l-0.51,0.095c-0.262,0.049-0.544,0.072-0.86,0.072c-0.444,0-0.895-0.047-1.331-0.093
		c-0.42-0.044-0.817-0.085-1.208-0.089c-0.907,0.332-1.677,0.481-2.497,0.481C103.018,15.953,101.172,14.856,101.173,12.762z
		 M101.428,12.762c-0.001,1.913,1.669,2.936,3.415,2.937c0.002,0,0.003,0,0.005,0c0.871,0,1.65-0.185,2.447-0.481
		c0.843,0.001,1.728,0.182,2.584,0.182c0.275,0,0.547-0.019,0.813-0.068c-1.373-1.227-1.219-6.315-1.926-8.376
		c-0.369-1.078-1.428-1.338-2.448-1.339c-0.003,0-0.005,0-0.008,0c-2.075,0-3.522,0.631-4.228,2.689
		c0.558-0.333,1.837-1.205,2.432-1.205c0,0,0,0,0,0c0.855,0.001,1.505,0.354,1.895,1.115c-1.729,0.575-3.809,1.668-4.609,3.377
		C101.653,11.927,101.428,12.41,101.428,12.762z M106.143,14.549l0-0.255c-0.565,0-0.921-0.348-1.119-0.64
		c-0.255-0.375-0.407-0.888-0.407-1.373c0.001-1.154,0.613-2.177,1.692-2.847c0.148,0.599,0.229,1.422,0.308,2.222
		c0.089,0.912,0.175,1.78,0.361,2.311c-0.02,0.013-0.04,0.026-0.06,0.039c-0.272,0.179-0.437,0.288-0.773,0.288L106.143,14.549
		c0,0,0.001,0,0.001,0c0.519,0,0.723-0.222,1.151-0.482c-0.446-0.854-0.387-3.713-0.833-5.014c-1.225,0.649-2.099,1.8-2.1,3.229
		C104.362,13.303,104.973,14.548,106.143,14.549z M38.17,2.804l-0.165-0.089l0.077-0.171c0.658-1.468,0.361-2.24,0.352-2.262
		L38.308,0l0.31,0l2.099,0.002l0.407,0l-0.247,0.323l-2.027,2.651l-0.104,0.136l-0.151-0.081L38.17,2.804z M38.265,2.626l0.426,0.228
		l2.027-2.651l-2.1-0.002C38.618,0.202,38.983,1.025,38.265,2.626z M96.488,13.892l0.004-5.031c0.001-1.104-0.207-1.521-0.709-2.526
		l-0.092-0.185l0.206,0.001c0.814,0,1.63-0.244,2.419-0.48c0.186-0.056,0.37-0.11,0.555-0.162l0.162-0.046L99.032,5.63l-0.007,8.39
		c0.175,0.473,0.523,0.847,0.928,1.252l0.217,0.218l-4.522-0.005l-0.074-0.23C96.137,14.851,96.487,14.482,96.488,13.892z
		 M96.745,8.86l-0.004,5.031c-0.001,0.616-0.333,1.021-0.717,1.339l3.534,0.003c-0.339-0.354-0.624-0.707-0.781-1.148l-0.007-0.043
		l0.006-8.243c-0.129,0.038-0.258,0.076-0.388,0.115C97.646,6.137,96.879,6.365,96.1,6.4C96.543,7.292,96.746,7.767,96.745,8.86z"/>
	</svg>
)

export default SanLuisLogo;
